import { MapSSO } from "@moodys-ma-platform/auth-sdk"
import { isDevEnv } from "constants/shared"

// eslint-disable-next-line class-methods-use-this
function createMapSsoInstance(redirectUri: string) {
  return new MapSSO({
    clientId: process.env.REACT_APP_SSO_CLIENT_ID as string,
    issuer: process.env.REACT_APP_SSO_ISSUER as string,
    redirectUri: redirectUri || `${window.location.origin}/sso/callback`,
    postLogoutRedirectUri: process.env.REACT_APP_BASE_URL
  })
}
class SSO {
  private static instance: MapSSO

  private constructor(redirectUri: string) {
    SSO.instance = createMapSsoInstance(redirectUri)
  }

  // Note: The way of having a singleton of SDK instance.
  public static getInstance(redirectUri: string = ""): MapSSO | null {
    if (isDevEnv) {
      return null
    }
    if (!process.env.REACT_APP_SSO_CLIENT_ID || !process.env.REACT_APP_SSO_ISSUER) {
      throw new Error("SSO client ID and issuer are required")
    }

    if (!SSO.instance) {
      new SSO(redirectUri) as unknown as MapSSO
    }
    return SSO.instance
  }
}

export default SSO
