import React, { createRef, useState, useContext } from "react"
import { Collapse, Row, Col, Space, Typography, FormInstance, Button } from "antd"
import { useTranslation } from "react-i18next"
import { CompanyForm } from "models/company-form.model"
import { AgGridReact } from "ag-grid-react"
import { Company } from "@moodys/cre-cpm-client-apis.apis.services.auth"
import { AuthContext } from "contexts/AuthContext"
import { getDefaultsForCompanyCreate } from "./RentRollFieldsTable/functions"
import RentRollFieldsTable from "./RentRollFieldsTable"

const gridRef = createRef<AgGridReact>()

const { Panel } = Collapse
const { Text, Paragraph } = Typography

enum PanelKeys {
  Unit = "unit",
  Asset = "asset"
}

const asterisk = <Text type="danger">*</Text>

export interface RentRollTabProps {
  form: FormInstance<CompanyForm>
  company?: Company
}

const RentRollTab: React.FC<RentRollTabProps> = ({ company, form }) => {
  const { userCompanyRegion } = useContext(AuthContext)

  const { t } = useTranslation("auth")
  const {
    mappable_unit_fields,
    exportable_unit_fields,
    mappable_asset_fields,
    exportable_asset_fields
  } =
    company?.attributes || (getDefaultsForCompanyCreate(userCompanyRegion) as Company["attributes"])

  const [isDefault, setIsDefault] = useState<number>(0)

  const resetToDefaults = (): void => {
    setIsDefault(isDefault + 1)
  }

  return (
    <>
      <Col span={24}>
        <Space className="rxd-full-width" direction="vertical" size={70}>
          <Row>
            <Col span={24}>
              <Text>{t("companies.rent_roll.description1")}</Text>
              <br />
              <Paragraph>{t("companies.rent_roll.description2")}</Paragraph>
            </Col>
            <Col span={24}>
              <Paragraph>
                {t("companies.rent_roll.note_split_1")}
                {asterisk}
                {t("companies.rent_roll.note_split_2")}
              </Paragraph>
            </Col>
          </Row>
        </Space>
      </Col>
      <Space
        className="rxd-full-width"
        direction="vertical"
        size={70}
        style={{ marginTop: "1rem" }}
      >
        <Collapse>
          <Panel
            header={
              <span style={{ fontWeight: "bold" }}>
                {t("companies.rent_roll.unit_panel_title")}
              </span>
            }
            key={PanelKeys.Unit}
          >
            <Row>
              <Col span={24}>
                <RentRollFieldsTable
                  gridRef={gridRef}
                  mappableFields={mappable_unit_fields}
                  exportableFields={exportable_unit_fields}
                  form={form}
                  type={PanelKeys.Unit}
                  isDefault={isDefault}
                  userCompanyRegion={userCompanyRegion}
                />
              </Col>
            </Row>
          </Panel>
          <Panel
            header={
              <span style={{ fontWeight: "bold" }}>
                {t("companies.rent_roll.asset_panel_title")}
              </span>
            }
            key={PanelKeys.Asset}
          >
            <Row>
              <Col span={24}>
                <RentRollFieldsTable
                  gridRef={gridRef}
                  mappableFields={mappable_asset_fields}
                  exportableFields={exportable_asset_fields}
                  form={form}
                  type={PanelKeys.Asset}
                  isDefault={isDefault}
                  userCompanyRegion={userCompanyRegion}
                />
              </Col>
            </Row>
          </Panel>
        </Collapse>
      </Space>
      <Row justify="end">
        <Button
          style={{ marginTop: "1rem" }}
          type="default"
          data-testid="company-rent-roll-reset-defaults-btn"
          onClick={resetToDefaults}
        >
          {t("shared.restore_default")}
        </Button>
      </Row>
    </>
  )
}

export default RentRollTab
