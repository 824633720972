import { getTestId } from "@moodys/cre-cpm.functions.testing"
import { Testable } from "@moodys/cre-cpm.models.testable"
import React from "react"
import "./index.less"

export interface ScrollableProps extends Testable {
  height: string
}

const Scrollable: React.FC<ScrollableProps> = ({ children, height, testId }) => (
  <div
    className="rxd-auth-scrollable"
    style={{ height }}
    data-testid={getTestId("scrollable-cp", testId)}
  >
    {children}
  </div>
)

export default Scrollable
