import React, { useContext, useEffect, useMemo, useState } from "react"
import { Language } from "@moodys/cre-cpm-client-apis.apis.services.auth"
import { useTranslation } from "react-i18next"
import { AuthContext } from "./AuthContext"

export interface AppContextProps {
  locale: string
  callback: string
  token: string
}
export interface AppContextProviderProps {
  children: React.ReactNode
}
export const initialAppContext: AppContextProps = {
  locale: "en",
  callback: process.env.REACT_APP_REDIRECT_URL ?? "",
  token: ""
}
export const AppContext = React.createContext(initialAppContext)
export const AppContextProvider = ({ children }: AppContextProviderProps) => {
  const [params] = useState<URLSearchParams>(new URLSearchParams(window.location.search))
  const [locale, setLocale] = useState<string>(params.get("locale") || initialAppContext.locale)
  const [callback] = useState<string>(params.get("callback") || initialAppContext.callback)
  const [token] = useState<string>(params.get("token") || initialAppContext.token)
  const { userLanguage } = useContext(AuthContext)
  const { i18n } = useTranslation("auth")

  useEffect(() => {
    const currentLocale = (params.get("locale") as Language) || userLanguage || "en"
    setLocale(currentLocale)
    i18n.changeLanguage(currentLocale)
  }, [userLanguage, params])

  const value = useMemo(
    () => ({
      callback,
      locale,
      token
    }),
    [callback, locale, token]
  )
  return <AppContext.Provider value={value}>{children}</AppContext.Provider>
}
