import React from "react"

export type FeatureFlagEnv = "development" | "test" | "cypress" | "staging" | "production"

export interface FeatureFlagsProps {
  availableEnv: FeatureFlagEnv[]
}
const FeatureFlags: React.FC<FeatureFlagsProps> = ({ children, availableEnv }) =>
  availableEnv.includes((process.env.REACT_APP_ENV ?? "production") as FeatureFlagEnv) ? (
    // FC doesnt not allow me to return React.Nodes but i do not want to add extra
    // markup so here as an exception im going to add the Fragment
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{children}</>
  ) : null

export default FeatureFlags
