import React, { useMemo } from "react"
import { Sortable, ApiResponse, PaginationFilters } from "@moodys/cre-cpm-client-apis.apis.api-base"
import { authApi, UserFilter, User } from "@moodys/cre-cpm-client-apis.apis.services.auth"
import { getBaseUrl } from "@moodys/cre-cpm.functions.window-location"
import { defaultPageSize } from "constants/shared"

export interface UserContextProps {
  exportUrl: string
  getUsers: (
    pagination: PaginationFilters,
    filters: UserFilter,
    sortable: Sortable
  ) => Promise<ApiResponse<User[]>>
  getUser: (userId: string) => Promise<ApiResponse<User>>
  updateUser: (user: User) => Promise<ApiResponse<User>>
  createUser: (user: User) => Promise<ApiResponse<User>>
  resendInvitation: (userId: string) => Promise<ApiResponse<void>>
  resetPassword: (email: string) => Promise<ApiResponse<void>>
  lockUser: (
    userUuid: number,
    lockAt: number | null,
    comapnyUuid: string
  ) => Promise<ApiResponse<void>>
}

export interface UserContextProviderProps {
  children: React.ReactNode
}

export const defaultPagination: PaginationFilters = {
  number: 1,
  size: defaultPageSize
}

export const defaultSortable: Sortable = {
  fieldName: "",
  fieldOrder: null
}

export const defaultFilters: UserFilter = {
  like: ""
}

export const initialUserContext: UserContextProps = {
  exportUrl: "",
  getUsers: () => Promise.reject(),
  getUser: () => Promise.reject(),
  updateUser: () => Promise.reject(),
  createUser: () => Promise.reject(),
  resendInvitation: () => Promise.reject(),
  resetPassword: () => Promise.reject(),
  lockUser: () => Promise.reject()
}

export const UserContext = React.createContext(initialUserContext)

export const UserContextProvider = ({ children }: UserContextProviderProps) => {
  const getUsers = (pagination: PaginationFilters, filters: UserFilter, sortable: Sortable) =>
    authApi.getUsers(pagination, filters, sortable)

  const getUser = (userId: string) => authApi.getUser(userId)

  const updateUser = (user: User) => authApi.updateUser(user)

  const createUser = (user: User) => authApi.createUser(user)

  const resendInvitation = (userId: string) =>
    authApi.resendInvitation(userId, process.env.REACT_APP_REDIRECT_URL ?? getBaseUrl())

  const lockUser = (userUuid: number, lockAt: number | null, comapnyUuid: string) =>
    authApi.lockUser(userUuid, lockAt, comapnyUuid)

  const resetPassword = (email: string) =>
    authApi.createUserRecover(email, process.env.REACT_APP_REDIRECT_URL ?? getBaseUrl())

  const exportUrl = ""

  const values = useMemo(
    () => ({
      exportUrl,
      getUsers,
      getUser,
      updateUser,
      createUser,
      resendInvitation,
      resetPassword,
      lockUser
    }),
    []
  )

  return <UserContext.Provider value={values}>{children}</UserContext.Provider>
}
