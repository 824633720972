import { LoanSensitivitySettings } from "@moodys/cre-cpm-client-apis.apis.services.auth"

const loanSensitivityRanges: LoanSensitivitySettings = {
  dscr: {
    lower: -5.0,
    upper: 5.0
  },
  ltv: {
    lower: 0,
    upper: 200
  },
  debt_yield: {
    lower: 0,
    upper: 20
  }
}

export const loanSensitivityDefaults = {
  dscr: {
    lower: 1.0,
    upper: 1.5
  },
  ltv: {
    lower: 60,
    upper: 80
  },
  debt_yield: {
    lower: 7,
    upper: 10
  }
}

export default loanSensitivityRanges
