import React from "react"
import { FormInstance } from "antd"
import { FeatureAccess } from "@moodys/cre-cpm-client-apis.apis.services.auth"
import { getTestId } from "@moodys/cre-cpm.functions.testing"
import { UserForm } from "models/user-form.model"
import FeatureAccessList from "components/Admin/FeatureAccessList"

export interface FeatureAccessTabProps {
  featureAccess?: FeatureAccess[]
  form: FormInstance<UserForm>
  enabledFeatureAccess?: FeatureAccess[]
  loading: boolean
}

const FeatureAccessTab: React.FC<FeatureAccessTabProps> = ({
  featureAccess,
  enabledFeatureAccess,
  form,
  loading
}) =>
  !loading ? (
    <FeatureAccessList
      featureAccess={featureAccess}
      form={form}
      enabledFeatureAccess={enabledFeatureAccess}
      testId={getTestId("user")}
      isUserAdminPage
    />
  ) : null

export default FeatureAccessTab
