import React from "react"
import { useTranslation } from "react-i18next"
import { Table, TablePaginationConfig } from "antd"
import { FilterValue, SorterResult, TableCurrentDataSource } from "antd/lib/table/interface"
import { User } from "@moodys/cre-cpm-client-apis.apis.services.auth"
import { Pagination } from "@moodys/cre-cpm-client-apis.apis.api-base"
import getTableConfiguration from "./functions"

export interface UserListProps {
  data?: User[]
  isSuperAdmin: boolean
  locale: string
  isLoading: boolean
  pagination: Pagination
  onLockHandler: (userUuid: number, lock: boolean, companyUuid: string) => void
  hasLowerAdmPermissionsThan: (user: User) => boolean
  onTableChange: (
    tablePagination: TablePaginationConfig,
    _filters: Record<string, FilterValue | null>,
    sorter: SorterResult<User> | SorterResult<User>[],
    extra: TableCurrentDataSource<User>
  ) => void
}

const UserList: React.FC<UserListProps> = ({
  data,
  isSuperAdmin,
  locale,
  isLoading,
  pagination,
  onLockHandler,
  hasLowerAdmPermissionsThan,
  onTableChange
}) => {
  const { t } = useTranslation("auth")

  return (
    <Table
      columns={getTableConfiguration(
        isSuperAdmin,
        t,
        locale,
        onLockHandler,
        hasLowerAdmPermissionsThan
      )}
      dataSource={data}
      rowKey="id"
      loading={isLoading}
      size="small"
      bordered
      className="rxd-auth-table-xsmall"
      pagination={{
        current: pagination.page,
        pageSize: pagination.per_page,
        total: pagination.total,
        showSizeChanger: false,
        showLessItems: true,
        // Thats not good idea because always will generate a new ref
        // but is the way that antd works
        // eslint-disable-next-line react/no-unstable-nested-components
        showTotal: (total, range) => (
          <span>
            {t("shared.list.table.counter", {
              minPage: range[0],
              maxPage: range[1],
              totalSize: total
            })}
          </span>
        )
      }}
      onChange={onTableChange}
    />
  )
}

export default React.memo(UserList)
