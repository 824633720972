import React, { useContext } from "react"
import { Switch, Route } from "react-router-dom"
import { Col, Row } from "antd"

import { AuthContext } from "contexts/AuthContext"
import { Routes as RoutesMap } from "constants/shared"
import { Navigate } from "components/Routing"
import { createRedirectToAdminWithCallback } from "functions/User/Session"
import Login from "pages/Login"
import PasswordRecovery from "pages/PasswordRecovery"
import PasswordReset from "pages/PasswordReset"
import AdminRoutes from "pages/Admin"
import Logout from "pages/Logout"
import SsoLogin from "pages/Sso/SsoLogin"
import SsoEntrypoint from "pages/Sso/Redirect"
import SsoLogout from "pages/Sso/SsoLogout"
import CompanyRoutes from "./pages/Company"

const Routes: React.FC = () => {
  const { sso } = useContext(AuthContext)

  return (
    <Row style={{ overflowY: "clip" }}>
      <Col span={24}>
        <Switch>
          <Route exact path={RoutesMap.root}>
            <Login />
          </Route>
          <Route exact path={RoutesMap.logout}>
            <Logout />
          </Route>
          <Route exact path={RoutesMap.passwordRecovery}>
            <PasswordRecovery />
          </Route>
          <Route path={RoutesMap.reset}>
            <PasswordReset />
          </Route>
          <Route path={RoutesMap.admin.root}>
            <AdminRoutes />
          </Route>
          <Route path={RoutesMap.company.root}>
            <CompanyRoutes />
          </Route>
          <Route exact path={RoutesMap.sso.login}>
            <SsoLogin />
          </Route>
          <Route exact path={RoutesMap.sso.callback}>
            <SsoEntrypoint />
          </Route>
          <Route exact path={RoutesMap.sso.logout}>
            <SsoLogout />
          </Route>
          <Route
            render={(location) => {
              return (
                !location.location.pathname.startsWith(`${RoutesMap.sso.root}/`) && (
                  <Navigate
                    to={createRedirectToAdminWithCallback(location.location.pathname, sso)}
                  />
                )
              )
            }}
          />
        </Switch>
      </Col>
    </Row>
  )
}

export default React.memo(Routes)
