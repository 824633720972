export enum Envs {
  DEV = "development",
  STAGE = "staging",
  PROD = "production"
}

export enum Regions {
  US = "us",
  EU = "eu"
}

export enum CompanyRegions {
  USA = "usa",
  EUR = "eur"
}
