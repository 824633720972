import React from "react"
import { useTranslation } from "react-i18next"
import { Spin } from "antd"
import ScreenCenterContent from "components/Shared/ScreenCenterContent"

const SsoLoader: React.FC<{ message?: string }> = ({ message }) => {
  const { t } = useTranslation("auth")
  return (
    <ScreenCenterContent>
      <Spin tip={t(message ?? "login.loading")} />
    </ScreenCenterContent>
  )
}

export default SsoLoader
