import React, { useContext } from "react"
import { LayoutContext } from "contexts/LayoutContext"
import "./index.less"
import { AuthContext } from "contexts/AuthContext"
import { useTranslation } from "react-i18next"
import { removeArrayByValue } from "@moodys/cre-cpm.functions.array"
import { getTestId } from "@moodys/cre-cpm.functions.testing"
import { Menu as MenuAntd } from "antd"
import { Testable } from "@moodys/cre-cpm.models.testable"
import Link from "components/Admin/Link"
import getConfigurationTop, {
  getConfigurationBottom,
  MenuKeys,
  SubMenuItemProps,
  SubMenuKeys
} from "./functions"
import BottomAffix from "./BottomAffix"
import Footer from "./Footer"
import Header from "./Header"

const { SubMenu } = MenuAntd

export interface MenuProps extends Testable {
  isCollapsed: boolean
}

const Menu: React.FC<MenuProps> = ({ isCollapsed, testId }) => {
  const { hasAnyFeatureAccess, hasRole, isAdmin } = useContext(AuthContext)
  const { menuKey, setOpenKeys, setMenuKey, openKeys } = useContext(LayoutContext)

  const onMenuChange = ({ selectedKeys }: { selectedKeys: string[] }) => {
    setMenuKey(selectedKeys[0] as MenuKeys)
  }

  const onSubmenuClick = (key: SubMenuKeys) => () => {
    const isOpened = openKeys.includes(key)
    setOpenKeys(isOpened ? removeArrayByValue(openKeys, [key]) : openKeys.concat(key))
  }

  const { t } = useTranslation("auth")

  const menuItemsTop = getConfigurationTop(t, onSubmenuClick)
  const menuItemsBottom = getConfigurationBottom(t)

  const renderMenu = (item: SubMenuItemProps): JSX.Element | null => {
    if (!hasAnyFeatureAccess(item.permissions)) return null
    if (!hasRole(item.allowedRoles)) return null
    return item.items ? (
      <SubMenu
        key={item.key}
        icon={<span className="rxd-auth-sidebar-sider__menu__icon">{item.icon}</span>}
        title={item.title}
        onTitleClick={item.onTitleClick}
        data-testid={getTestId("rxd_auth_sider__menu__submenu", item.key)}
      >
        {item.items.map(
          (child) =>
            hasAnyFeatureAccess(child.permissions) &&
            hasRole(child.allowedRoles) && (
              <MenuAntd.Item
                key={child.key}
                data-testid={getTestId("rxd_auth_sider__menu__menu", child.key)}
                icon={<span className="rxd-auth-sidebar-sider__menu__icon">{child.icon}</span>}
              >
                <Link
                  to={child.to}
                  target={child.blankPage ? "_blank" : undefined}
                  data-testid={getTestId("rxd_auth_sider__menu__menu__link", child.key)}
                >
                  {child.title}
                </Link>
              </MenuAntd.Item>
            )
        )}
      </SubMenu>
    ) : (
      <MenuAntd.Item
        key={item.key}
        icon={<span className="rxd-auth-sidebar-sider__menu__icon">{item.icon}</span>}
        data-testid={getTestId("rxd_auth_sider__menu__menu", item.key)}
      >
        {item.isNotClickable ? (
          item.title
        ) : (
          <Link
            to={item.to ?? ""}
            target={item.blankPage ? "_blank" : undefined}
            data-testid={getTestId("rxd_auth_sider__menu__menu__link", item.key)}
          >
            {item.title}
          </Link>
        )}
      </MenuAntd.Item>
    )
  }
  return (
    <MenuAntd
      theme="dark"
      className="rxd-auth-sidebar-sider__menu"
      multiple={false}
      mode="inline"
      onSelect={onMenuChange}
      inlineCollapsed={isCollapsed}
      openKeys={isCollapsed ? [] : openKeys}
      selectedKeys={menuKey ? [menuKey] : undefined}
      data-testid={getTestId("rxd_auth_sider__menu", testId)}
    >
      <Header collapsed={isCollapsed} testId="rxd_sidebar_sider__menu__header" />
      {isAdmin() && menuItemsTop.map(renderMenu)}
      <BottomAffix />
      <MenuAntd.Divider key="menu-divider" />
      {menuItemsBottom.map(renderMenu)}
      <Footer collapsed={isCollapsed} testId="rxd_sidebar_sider__menu__footer" />
    </MenuAntd>
  )
}

export default Menu
