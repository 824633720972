import { LayoutContext } from "contexts/LayoutContext"
import { MenuKeys, SubMenuKeys } from "pages/Admin/Menu/functions"
import { useEffect, useContext } from "react"

const useUpdateMenu = (subMenuKey: SubMenuKeys, menuKey?: MenuKeys) => {
  const { setMenuKey, mergeOpenKeys, hasChanges } = useContext(LayoutContext)

  useEffect(() => {
    if (hasChanges(subMenuKey, menuKey)) {
      mergeOpenKeys(subMenuKey)
      if (menuKey) {
        setMenuKey(menuKey)
      }
    }
    // eslint-disable-next-line
  }, [])

  return []
}

export default useUpdateMenu
