import React, { useContext, useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import { Form } from "antd"
import { User } from "@moodys/cre-cpm-client-apis.apis.services.auth"
import { getTestId } from "@moodys/cre-cpm.functions.testing"
import Layout from "pages/Admin/Layout"
import TwoCardForm from "components/Admin/TwoCardForm"
import { UserForm } from "models/user-form.model"
import { UserContext } from "contexts/UserContext"
import { AuthContext } from "contexts/AuthContext"
import { showSaveSuccessMessage } from "functions/Messages"
import { Routes } from "constants/shared"
import { populateUserFromForm } from "functions/Form"
import { useQuery } from "@moodys/cre-cpm.hooks.use-query"
import handleApiError from "functions/Exceptions"
import LeftForm from "./LeftForm"

const EditUser: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()

  const [form] = Form.useForm<UserForm>()
  const [userForm, setUserForm] = useState<UserForm | undefined>()
  const { getUser, updateUser } = useContext(UserContext)
  const { userType, userCompanyUuid, hasLowerAdmPermissionsThan, hasAnyFeatureAccess } =
    useContext(AuthContext)
  const [user, loadingUser, , , , setUser] = useQuery<User | undefined>(
    () => getUser(id),

    {
      disabled: !id,
      onSuccess: (response) => {
        if (response.data && hasLowerAdmPermissionsThan(response.data)) {
          history.push(Routes.admin.users.root)
        }
      },
      onError: (err) => {
        handleApiError(err)
      }
    }
  )

  const [, isUpdatingUser, , , updateUserRequest] = useQuery(
    () =>
      !userForm || !user?.id || !userCompanyUuid
        ? Promise.reject()
        : updateUser(
            populateUserFromForm(userForm, userType, userCompanyUuid, hasAnyFeatureAccess, user?.id)
          ),
    {
      disabled: true,
      onError: (err) => {
        handleApiError(err)
      },
      onSuccess: (response) => {
        if (response.data) {
          setUser(response.data)
          showSaveSuccessMessage()
        }
      }
    }
  )

  const onFinish = (values: UserForm) => {
    setUserForm(values)
  }

  useEffect(() => {
    if (userForm && id && user?.id && userCompanyUuid) {
      updateUserRequest()
    }
    // eslint-disable-next-line
  }, [userForm])
  return id ? (
    <Layout useCard={false}>
      <TwoCardForm
        form={form}
        onFinish={onFinish}
        testId={getTestId("user")}
        leftCard={
          <LeftForm form={form} user={user} id={id} saving={isUpdatingUser} loading={loadingUser} />
        }
      />
    </Layout>
  ) : null
}

export default EditUser
