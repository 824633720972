import { List, message } from "antd"
import React from "react"
import {
  ApiResponseKind,
  ApiError as IssueApiError,
  ApiExceptionCodes
} from "@moodys/cre-cpm-client-apis.apis.api-base"
import authI18n from "functions/I18n"

export const showSaveSuccessMessage = (customKeyMessage?: string) => {
  // test
  const content = authI18n.t(customKeyMessage ?? "shared.save_success")
  message.success(content)
}

export const showUnexpectedError = () => {
  message.error({ content: <p>{authI18n.t("shared.errors.unexpected.text")}</p> })
}

export const getMessageByApiExceptionCode = (issues?: IssueApiError[]): JSX.Element => (
  <List size="small">
    {issues?.map((issue) => {
      switch (issue.code) {
        case ApiExceptionCodes.blank_attribute:
        case ApiExceptionCodes.length_exceeded:
        case ApiExceptionCodes.uniqueness_constraint_violation:
          return <List.Item>{`${authI18n.t(`errors.${issue.code}`)} ${issue.title}`}</List.Item>

        default:
          return <List.Item>{`${authI18n.t(`errors.${issue.code}`)}`}</List.Item>
      }
    })}
  </List>
)

export const showApiError = (kind: ApiResponseKind = "unknown", issues: IssueApiError[] = []) => {
  switch (kind) {
    case "unauthorized":
      message.error(authI18n.t("login.invalidCredentials"))
      break

    default:
      if (issues?.length) {
        message.error({
          content: getMessageByApiExceptionCode(issues)
        })
      } else {
        showUnexpectedError()
      }
  }
}
