import { faLock, faUnlock } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, Tooltip } from "antd"
import { getTestId } from "@moodys/cre-cpm.functions.testing"
import { Testable } from "@moodys/cre-cpm.models.testable"
import React from "react"
import { useTranslation } from "react-i18next"

export interface LockButtonProps extends Testable {
  isLock: boolean
  onClick?: () => void
}

const LockButton: React.FC<LockButtonProps> = ({ isLock, testId, onClick }) => {
  const { t } = useTranslation("auth")

  return (
    <Tooltip placement="top" title={isLock ? t(`shared.unlock`) : t(`shared.lock`)}>
      <Button
        onClick={onClick}
        type="link"
        size="small"
        data-testid={getTestId("lock-btn", testId)}
      >
        <FontAwesomeIcon icon={isLock ? faLock : faUnlock} />
      </Button>
    </Tooltip>
  )
}

export default React.memo(LockButton)
