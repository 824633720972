import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Button, Col, FormInstance, Row, Select, Spin } from "antd"
import { Company } from "@moodys/cre-cpm-client-apis.apis.services.auth"
import { getTestId } from "@moodys/cre-cpm.functions.testing"
import { useQuery } from "@moodys/cre-cpm.hooks.use-query"
import ThinFormItem from "components/Antd/ThinFormItem"
import { PlatformContext } from "contexts/PlatformContext"
import { CompanyForm } from "models/company-form.model"
import handleApiError from "functions/Exceptions"
import { Portfolio } from "@moodys/cre-cpm-client-apis.apis.services.platform"

const { Option } = Select

export interface PortfoliosTabProps {
  company?: Company
  form: FormInstance<CompanyForm>
}

const PortfoliosTab: React.FC<PortfoliosTabProps> = ({ company, form }) => {
  const { t } = useTranslation("auth")
  const { getPortfolios, deletePortfolio } = useContext(PlatformContext)
  const [filteredPortfolios, setFilteredPortfolios] = useState<Portfolio[] | undefined>([])
  const [portfolioToDeleteId, setPortfolioToDeleteId] = useState("")
  const [portfolios, loadingPortfolios, , , , setPortfolios] = useQuery(
    () => getPortfolios({ company_ref_uuid: company?.id ?? "" }),
    {
      onError: (err) => {
        handleApiError(err)
      }
    }
  )

  const [, isDeletingPortfolio, , , deletePortfolioRequest] = useQuery(
    () => deletePortfolio(portfolioToDeleteId, company?.id ?? ""),
    {
      disabled: true,
      onSuccess: () => {
        setPortfolios(portfolios?.filter((portfolio) => portfolio.id !== portfolioToDeleteId) ?? [])
      },
      onError: (err) => {
        handleApiError(err)
      }
    }
  )

  const onDeletePortfolio = () => {
    if (portfolioToDeleteId) {
      deletePortfolioRequest()
    }
  }

  const onPortfolioSearch = (value: string) => {
    if (value) {
      setFilteredPortfolios(
        portfolios?.filter(
          (portfolio) =>
            portfolio.attributes?.name.toLowerCase().indexOf(value.toLowerCase()) !== -1
        )
      )
    } else {
      setFilteredPortfolios(portfolios)
    }
  }

  const onPortfolioChange = (value: string) => {
    setPortfolioToDeleteId(value)
  }

  useEffect(() => {
    setFilteredPortfolios(portfolios)
    setPortfolioToDeleteId("")
    form.resetFields(["portfolio_to_delete"])
  }, [portfolios])

  return (
    <Row>
      <Col span={12}>
        <ThinFormItem name="portfolio_to_delete" label={t("shared.portfolios")}>
          <Spin spinning={loadingPortfolios}>
            <Select
              allowClear
              showSearch
              placeholder={t("shared.search_by_portfolios")}
              defaultActiveFirstOption={false}
              filterOption={false}
              onSearch={onPortfolioSearch}
              data-testid={getTestId("user_leftform__search_portfolios_dropdown")}
              onChange={onPortfolioChange}
              loading={loadingPortfolios}
            >
              {filteredPortfolios?.map((portfolio) => (
                <Option key={portfolio.id} value={portfolio.id}>
                  {portfolio.attributes?.name}
                </Option>
              ))}
            </Select>
          </Spin>
        </ThinFormItem>
      </Col>
      <Col span={10} offset={2}>
        <ThinFormItem label=" ">
          <Button
            disabled={!portfolioToDeleteId || isDeletingPortfolio}
            loading={isDeletingPortfolio}
            onClick={onDeletePortfolio}
            type="primary"
          >
            {t("shared.delete")}
          </Button>
        </ThinFormItem>
      </Col>
    </Row>
  )
}

export default PortfoliosTab
