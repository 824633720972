import React, { useMemo } from "react"
import { authApi, Company, CompanyFilter } from "@moodys/cre-cpm-client-apis.apis.services.auth"
import { Sortable, ApiResponse, PaginationFilters } from "@moodys/cre-cpm-client-apis.apis.api-base"
import { defaultPageSize } from "constants/shared"

export interface CompanyContextProps {
  exportUrl: string
  createCompany: (company: Company) => Promise<ApiResponse<Company>>
  getCompanies: (
    pagination: PaginationFilters,
    filters: CompanyFilter,
    sortable: Sortable
  ) => Promise<ApiResponse<Company[]>>
  getCompany: (companyUuid: string) => Promise<ApiResponse<Company>>
  updateCompany: (company: Company) => Promise<ApiResponse<Company>>
}

export interface CompanyContextProviderProps {
  children: React.ReactNode
}

export const defaultPagination: PaginationFilters = {
  number: 1,
  size: defaultPageSize
}

export const defaultSortable: Sortable = {
  fieldName: "",
  fieldOrder: null
}

export const defaultFilters: CompanyFilter = {
  like: ""
}

export const initialCompanyContext: CompanyContextProps = {
  exportUrl: "",
  createCompany: () => Promise.reject(),
  getCompanies: () => Promise.reject(),
  getCompany: () => Promise.reject(),
  updateCompany: () => Promise.reject()
}

export const CompanyContext = React.createContext(initialCompanyContext)

export const CompanyContextProvider = ({ children }: CompanyContextProviderProps) => {
  const getCompanies = (
    pagination: PaginationFilters,
    filters: CompanyFilter,
    sortable: Sortable
  ) => authApi.getCompanies(pagination, filters, sortable)

  const createCompany = (company: Company) => authApi.createCompany(company)

  const getCompany = (companyUuid: string) => authApi.getCompany(companyUuid)

  const updateCompany = (company: Company) => authApi.updateCompany(company)

  const exportUrl = ""

  const values = useMemo(
    () => ({
      exportUrl,
      createCompany,
      getCompanies,
      getCompany,
      updateCompany
    }),
    []
  )

  return <CompanyContext.Provider value={values}>{children}</CompanyContext.Provider>
}
