import { Envs, CompanyRegions } from "models/envs.model"
import {
  defaultMappableFieldsObject,
  defaultExportableFieldsObject,
  EUCreateCompanyDefaultFields,
  USCreateCompanyDefaultFields
} from "constants/rentRollFields"

const environment = process.env.REACT_APP_ENV as Envs

export type FieldProps = {
  data: {
    fieldName: string
    availableForMapping: boolean
    availableForExport: boolean
  }
}

export const getRegionEnvironment = (env: Envs, region: string): string => {
  switch (env) {
    case Envs.STAGE:
    case Envs.DEV:
      return "eur"
    case Envs.PROD:
      return region === CompanyRegions.EUR ? "eur" : "usa"
    default:
      return "eur"
  }
}

export const getDefaultFields = (
  type: string,
  region: string,
  fieldType: "mapping" | "export",
  mappingFields = defaultMappableFieldsObject,
  exportFields = defaultExportableFieldsObject
) => {
  const fields = fieldType === "mapping" ? mappingFields : exportFields
  if (type === "unit") {
    return region === CompanyRegions.EUR ? fields.unitEU : fields.unitUS
  }
  return region === CompanyRegions.EUR ? fields.assetEU : fields.assetUS
}

export const getDefaultsForCompanyCreate = (userCompanyRegion: string) => {
  const region = getRegionEnvironment(environment, userCompanyRegion)
  return region === "eur" ? EUCreateCompanyDefaultFields : USCreateCompanyDefaultFields
}

export const handleCheckboxChange = (
  data: FieldProps["data"],
  fieldKey: "availableForMapping" | "availableForExport",
  stateArray: unknown[],
  setStateArray: (newState: any[]) => void
) => {
  const field = data.fieldName
  const updatedData = {
    ...data,
    [fieldKey]: !data[fieldKey]
  }

  const newStateArray = updatedData[fieldKey]
    ? stateArray.filter((item) => item !== field)
    : [...stateArray, field]

  setStateArray(newStateArray)
}
