import React from "react"
import { Col, Row } from "antd"

export interface ContainerProps {
  children: React.ReactElement | React.ReactElement[]
}

const Container: React.FC<ContainerProps> = ({ children }) => {
  return (
    <Row justify="space-around" align="middle" className="rxd-auth-full-height">
      <Col span={24}>{children}</Col>
    </Row>
  )
}

export default Container
