import React, { useMemo } from "react"
import { ApiResponse } from "@moodys/cre-cpm-client-apis.apis.api-base"
import { Portfolio, PortfolioFilter } from "@moodys/cre-cpm-client-apis.apis.services.platform"
import platformApi from "api/services/platform"

export interface PlatformContextProps {
  getPortfolios: (filter?: PortfolioFilter) => Promise<ApiResponse<Portfolio[]>>
  deletePortfolio: (portfolioId: string, companyRefUuid: string) => Promise<ApiResponse<void>>
}

export interface PlatformContextProviderProps {
  children: React.ReactNode
}

export const initialPlatformContext: PlatformContextProps = {
  getPortfolios: () => Promise.reject(),
  deletePortfolio: () => Promise.resolve({ kind: "ok", data: undefined })
}

export const PlatformContext = React.createContext(initialPlatformContext)

export const PlatformContextProvider = ({ children }: PlatformContextProviderProps) => {
  const getPortfolios = (filter?: PortfolioFilter) =>
    platformApi.getPortfolios(["name", "company_ref_uuid"], filter)
  const deletePortfolio = (portfolioId: string, companyRefUuid: string) =>
    platformApi.deletePortfolio(portfolioId, companyRefUuid)

  const values = useMemo(
    () => ({
      getPortfolios,
      deletePortfolio
    }),
    []
  )

  return <PlatformContext.Provider value={values}>{children}</PlatformContext.Provider>
}
