import { Row, Space } from "antd"
import EditLink from "components/Admin/TableActions/EditLink"
import LockButton from "components/Admin/TableActions/LockButton/LockButton"
import { Routes } from "constants/shared"
import { getTestId } from "@moodys/cre-cpm.functions.testing"
import { Testable } from "@moodys/cre-cpm.models.testable"
import React from "react"

interface ActionItemProps extends Testable {
  id: string
  actionsAllowed: boolean
  testId: string
  isLock: boolean
  onLock?: () => void
}

const ActionItem: React.FC<ActionItemProps> = ({ id, actionsAllowed, testId, isLock, onLock }) => (
  <Row justify="center">
    <Space direction="horizontal" size="middle">
      {actionsAllowed && (
        <>
          <EditLink
            to={`${Routes.admin.users.edit}/${id}`}
            testId={getTestId("user_list__edit_btn", testId)}
          />
          <LockButton
            testId={getTestId("user_list__lock_btn", testId)}
            onClick={onLock}
            isLock={isLock}
          />
        </>
      )}
    </Space>
  </Row>
)

export default React.memo(ActionItem)
