import { useState } from "react"
import { SorterResult, TableCurrentDataSource } from "antd/lib/table/interface"
import { Sortable } from "@moodys/cre-cpm-client-apis.apis.api-base"
import { processSorting } from "functions/Table"

export interface SortableMapping {
  [key: string]: string
}

const useSortable = <T>(
  initialSortable: Sortable,
  extraMappings?: SortableMapping
): [
  Sortable,
  (sorter: SorterResult<T> | SorterResult<T>[], extra: TableCurrentDataSource<T>) => void
] => {
  const [sortable, setSortable] = useState(initialSortable)

  const onSorting = (
    sorter: SorterResult<T> | SorterResult<T>[],
    extra: TableCurrentDataSource<T>
  ) => {
    processSorting(extra, sorter, sortable, setSortable, extraMappings)
  }

  return [sortable, onSorting]
}

export default useSortable
