import React from "react"
import { useTranslation } from "react-i18next"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExclamationCircle, faLock } from "@fortawesome/free-solid-svg-icons"
import { faCheckCircle, faClock } from "@fortawesome/free-regular-svg-icons"
import { User } from "@moodys/cre-cpm-client-apis.apis.services.auth"
import { Tag } from "antd"
import { isUserExpired, isUserLocked } from "functions/User/Session"

export interface UserStatusProps {
  user: User
}

const UserStatus: React.FC<UserStatusProps> = ({ user }) => {
  const { t } = useTranslation("auth")
  return (
    <>
      {isUserLocked(user.attributes?.locked_at) && (
        <Tag color="error" icon={<FontAwesomeIcon icon={faLock} />}>
          &nbsp;
          {t("shared.locked")}
        </Tag>
      )}
      {!isUserLocked(user.attributes?.locked_at) &&
        isUserExpired(user.attributes?.user_type, user.attributes?.expiration_date) && (
          <Tag color="default" icon={<FontAwesomeIcon icon={faExclamationCircle} />}>
            &nbsp;
            {t("shared.expired")}
          </Tag>
        )}
      {!isUserLocked(user.attributes?.locked_at) &&
        !user.attributes?.active &&
        !isUserExpired(user.attributes?.user_type, user.attributes?.expiration_date) && (
          <Tag color="warning" icon={<FontAwesomeIcon icon={faClock} />}>
            &nbsp;
            {t("shared.pending")}
          </Tag>
        )}
      {!isUserLocked(user.attributes?.locked_at) &&
        !isUserExpired(user.attributes?.user_type, user.attributes?.expiration_date) &&
        user.attributes?.active && (
          <Tag color="success" icon={<FontAwesomeIcon icon={faCheckCircle} />}>
            &nbsp;
            {t("shared.active")}
          </Tag>
        )}
    </>
  )
}

export default UserStatus
