import { Button, Col, Form, Input, message, Row, Typography } from "antd"
import React, { useContext } from "react"
import { useTranslation } from "react-i18next"
import { authApi } from "@moodys/cre-cpm-client-apis.apis.services.auth"
import { getTestId } from "@moodys/cre-cpm.functions.testing"
import Container from "components/Container"
import AuthCard from "components/AuthCard"
import { AppContext } from "contexts/AppContext"

const PasswordRecovery = () => {
  const { t } = useTranslation("auth")
  const { callback } = useContext(AppContext)
  const onFinish = ({ email }: { email: string }) => {
    authApi
      .createUserRecover(email, callback)
      .then((response) => {
        if (response.kind === "ok") {
          message.success(t("passwordRecovery.successMessage"))
        } else {
          message.error(response.kind)
        }
      })
      .catch(() => {
        message.error(t("shared.errors.unexpected.text"))
      })
  }
  return (
    <Container>
      <AuthCard>
        <Row>
          <Col span={24} style={{ textAlign: "center", fontSize: 20 }}>
            <Typography.Text data-testid={getTestId("password-recovery-title")} strong>
              {t("passwordRecovery.forgotPassword")}
            </Typography.Text>
          </Col>
          <Col span={24} style={{ textAlign: "center" }}>
            <Typography.Text data-testid={getTestId("password-recovery-subtitle")}>
              {t("passwordRecovery.instructions")}
            </Typography.Text>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form
              layout="vertical"
              onFinish={onFinish}
              requiredMark={false}
              style={{ width: "100%" }}
              data-testid={getTestId("password_recovery__form")}
            >
              <Row>
                <Col span={24}>
                  <Form.Item
                    label={t("passwordRecovery.email")}
                    name="email"
                    className="email"
                    rules={[
                      { type: "email", message: t("shared.errors.invalidFormat") },
                      { required: true, message: t("shared.errors.fieldRequired") }
                    ]}
                  >
                    <Input
                      className="user-email-input"
                      size="large"
                      data-testid={getTestId("password-recovery-user-email")}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24} style={{ textAlign: "center" }}>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      size="large"
                      className="recover-button btn-brand"
                      style={{ width: "100%" }}
                      data-testid={getTestId("password-recovery-submit-button")}
                    >
                      {t("passwordRecovery.resetButton")}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </AuthCard>
    </Container>
  )
}

export default PasswordRecovery
