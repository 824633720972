import React from "react"
import { TFunction } from "i18next"
import { Row } from "antd"
import { ColumnsType } from "antd/lib/table"
import { Company } from "@moodys/cre-cpm-client-apis.apis.services.auth"
import { formatNumberToIntegerTruncatedString } from "@moodys/cre-cpm.functions.format"
import { getTestId } from "@moodys/cre-cpm.functions.testing"
import { Routes } from "constants/shared"
import EditLink from "components/Admin/TableActions/EditLink"

const getTableConfiguration = (t: TFunction) =>
  [
    {
      title: t("companies.company_name"),
      dataIndex: ["attributes", "name"],
      key: "name",
      sorter: true,
      ellipsis: true,
      onCell: (record: Company) => ({
        "data-testid": getTestId(`company-column-name-${record.id}-${record.attributes?.name}`)
      })
    },
    {
      title: t("companies.internal_users_and_limit"),
      dataIndex: ["attributes", "internal_users_count"],
      key: "internal_users",
      align: "left",
      sorter: false,
      onCell: (record: Company) => ({
        "data-testid": getTestId(
          `company-column-internal-users-and-limit-${record.id}-${record.attributes?.internal_users_count}-${record.attributes.internal_users_limit}`
        )
      }),
      render: (internalUsers: number, record: Company) =>
        `${formatNumberToIntegerTruncatedString(
          internalUsers
        )} (${formatNumberToIntegerTruncatedString(record.attributes.internal_users_limit)})`
    },
    {
      title: t("companies.external_users_and_limit"),
      dataIndex: ["attributes", "external_users_count"],
      key: "external_users",
      align: "left",
      sorter: false,
      onCell: (record: Company) => ({
        "data-testid": getTestId(
          `company-column-external-users-and-limit-${record.id}-${record.attributes?.external_users_count}-${record.attributes.external_users_limit}`
        )
      }),
      render: (externallUsers: number, record: Company) =>
        `${formatNumberToIntegerTruncatedString(
          externallUsers
        )} (${formatNumberToIntegerTruncatedString(record.attributes.external_users_limit)})`
    },
    {
      title: t("shared.language"),
      dataIndex: ["attributes", "language"],
      key: "language",
      align: "center",
      sorter: true,
      onCell: (record: Company) => ({
        "data-testid": getTestId(
          `company-column-language-${record.id}-${record.attributes.language}`
        )
      })
    },
    {
      title: t("shared.action"),
      align: "center",
      dataIndex: ["id"],
      onCell: () => ({
        "data-testid": getTestId(`company-column-action`)
      }),
      render: (id: string) => (
        <Row justify="center">
          <EditLink
            to={`${Routes.admin.companies.edit}/${id}`}
            data-testid={getTestId(`company-column-edit-btn-${id}`)}
          />
        </Row>
      )
    }
  ] as ColumnsType<Company>

export default getTableConfiguration
