import { OptionFC } from "rc-select/lib/Option"
import React from "react"
import { Select } from "antd"
import { Portfolio } from "@moodys/cre-cpm-client-apis.apis.services.platform"
import authI18n from "functions/I18n"

const { Option } = Select

export const AllKey = "all"

const getPortfoliosOptions = (portfolios?: Portfolio[]): React.ReactElement<OptionFC>[] =>
  [<Option key={AllKey}>{authI18n.t("shared.all")}</Option>].concat(
    (portfolios ?? []).map((portfolio) => (
      <Option key={portfolio.id}>{portfolio.attributes?.name}</Option>
    ))
  )

export default getPortfoliosOptions
