import { Card, Col, Row, Space, Typography } from "antd"
import { CardMinHeight } from "constants/shared"
import { getTestId } from "@moodys/cre-cpm.functions.testing"
import { Testable } from "@moodys/cre-cpm.models.testable"
import React from "react"

export interface FormCardProps extends Testable {
  title?: string
  subtitle?: string
}

const FormCard: React.FC<FormCardProps> = ({ title, subtitle, children, testId }) => {
  return (
    <Card
      style={{ minHeight: CardMinHeight, position: "relative" }}
      data-testid={getTestId("form_card__card", testId)}
    >
      <Space direction="vertical" size="middle" className="rxd-full-width">
        {title && (
          <Row>
            <Col>
              <Typography.Title level={3} data-testid={getTestId("form_card__title", testId)}>
                {title}
              </Typography.Title>
            </Col>
          </Row>
        )}
        {subtitle && (
          <Row>
            <Typography.Title level={4} data-testid={getTestId("form_card__subtitle", testId)}>
              {subtitle}
            </Typography.Title>
          </Row>
        )}
        <Row>
          <Col
            span={23}
            style={{ position: "inherit" }}
            data-testid={getTestId("form_card__content", testId)}
          >
            {children}
          </Col>
        </Row>
      </Space>
    </Card>
  )
}

export default FormCard
