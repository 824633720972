/* eslint-disable no-fallthrough */
import { getLocationPath } from "@moodys/cre-cpm.functions.window-location"
import { Routes } from "constants/shared"

const isPublicAccessView = () => {
  switch (getLocationPath()) {
    case Routes.logout:
    case Routes.root:
    case Routes.passwordRecovery:
    case Routes.sso.login:
    case Routes.sso.callback:
    case Routes.reset:
      return true
    default:
      return false
  }
}

export default isPublicAccessView
