import React from "react"
import { getTestId } from "@moodys/cre-cpm.functions.testing"
import moodysCreLogo from "images/top_logo.svg"

const logoHeight = 40

export interface LogoProps {
  url?: string
}

const Logo: React.FC<LogoProps> = ({ url }) => (
  <img
    src={url ?? moodysCreLogo}
    alt="logo"
    data-testid={getTestId("logo-cp")}
    height={logoHeight}
  />
)

export default Logo
