import { Button } from "antd"
import { getTestId } from "@moodys/cre-cpm.functions.testing"
import { Testable } from "@moodys/cre-cpm.models.testable"
import React from "react"
import { Link } from "react-router-dom"

export interface NavButtonProps extends Testable {
  title: string
  to: string
}

const NavButton: React.FC<NavButtonProps> = ({ title, to, testId }) => (
  <Link to={to} type="link" data-testid={getTestId("nav_btn_cp", testId)}>
    <Button type="primary" data-testid={getTestId("nav_btn_co__btn", testId)}>
      {title}
    </Button>
  </Link>
)

export default React.memo(NavButton)
