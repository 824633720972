import { faFileExcel } from "@fortawesome/free-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Space } from "antd"
import { getTestId } from "@moodys/cre-cpm.functions.testing"
import { Testable } from "@moodys/cre-cpm.models.testable"
import React from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

export interface ExportButtonProps extends Testable {
  to: string
}

const ExportButton: React.FC<ExportButtonProps> = ({ to, testId }) => {
  const { t } = useTranslation("auth")

  return (
    <Link to={to} type="link" data-testid={getTestId("export_btn", testId)}>
      <Space direction="horizontal" size="small">
        <FontAwesomeIcon icon={faFileExcel} />
        <span>{t("shared.export")}</span>
      </Space>
    </Link>
  )
}

export default React.memo(ExportButton)
