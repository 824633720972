import { Row } from "antd"
import { getTestId } from "@moodys/cre-cpm.functions.testing"
import React from "react"
import "./index.less"

const Content: React.FC = ({ children }) => (
  <main className="rxd-auth-content" data-testid={getTestId("content")}>
    <Row style={{ margin: "auto", width: "100%" }}>{children}</Row>
  </main>
)

export default Content
