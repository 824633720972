import React, { useRef, useState } from "react"
import { ColorResult, SketchPicker } from "react-color"
import { useTranslation } from "react-i18next"
import { Button, Col, Divider, Popover, Row, Space, Typography } from "antd"
import { ColorKey } from "@moodys/cre-cpm-client-apis.apis.services.auth"
import { getTestId } from "@moodys/cre-cpm.functions.testing"
import { Testable } from "@moodys/cre-cpm.models.testable"
import ColorPickerButton from "./ColorPickerButton"
import "./index.less"

export interface ColorPickerBoxProps extends Testable {
  name: string
  description: string
  hexValue: string
  id: ColorKey
  onChange: (id: ColorKey, color: string) => void
}

const ColorPickerBox: React.FC<ColorPickerBoxProps> = ({
  name,
  description,
  hexValue,
  id,
  testId,
  onChange
}) => {
  const [color, setColor] = useState(hexValue)
  const [visible, setVisible] = useState(false)
  const { t } = useTranslation("auth")
  const ref = useRef<HTMLDivElement>(document.createElement("div"))

  const onChangeCompleteHandler = (newColor: ColorResult) => {
    setColor(newColor.hex)
  }

  const onChangeHandler = (newColor: ColorResult) => {
    setColor(newColor.hex)
  }

  const onClickHandler = () => {
    setVisible(true)
  }

  const onAccept = () => {
    onChange(id, color)
    setVisible(false)
  }

  const onCancel = () => {
    setColor(hexValue)
    setVisible(false)
  }

  const onVisibleChange = (isVisible: boolean) => {
    setColor(hexValue)
    setVisible(isVisible)
  }

  return (
    <div className="rxd-color-picker-box" data-testid={getTestId("color-picker-box-cp", testId)}>
      <Typography.Text strong>{name}</Typography.Text>
      <Popover
        onVisibleChange={onVisibleChange}
        trigger="click"
        data-testid={getTestId("color-picker--box-cp__popover", testId)}
        content={
          <div ref={ref}>
            <Row justify="center">
              <Col span={24}>
                <Space direction="vertical" className="rxd-full-width">
                  <Row justify="center">
                    <SketchPicker
                      color={color}
                      onChange={onChangeHandler}
                      onChangeComplete={onChangeCompleteHandler}
                      disableAlpha
                      data-testid={getTestId("color-picker-cp__sketchpicker", testId)}
                    />
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Space direction="horizontal">
                        <Typography.Text type="secondary">
                          {t("companies.stylesheet.selected.text")}
                        </Typography.Text>
                        <div
                          className="rxd-color-picker-box-selected"
                          style={{ backgroundColor: color }}
                        />
                      </Space>
                    </Col>
                  </Row>
                  <Row>
                    <Divider style={{ marginTop: 8, marginBottom: 8 }} />
                  </Row>
                  <Row justify="center">
                    <Space direction="horizontal">
                      <Button
                        type="primary"
                        onClick={onAccept}
                        data-testid={getTestId("color-picker-cp__accept-btn", testId)}
                      >
                        {t("shared.accept")}
                      </Button>
                      <Button
                        type="default"
                        onClick={onCancel}
                        data-testid={getTestId("color-picker-cp__cancel-btn", testId)}
                      >
                        {t("shared.cancel")}
                      </Button>
                    </Space>
                  </Row>
                </Space>
              </Col>
            </Row>
          </div>
        }
        visible={visible}
      >
        <ColorPickerButton onClick={onClickHandler} hexValue={hexValue} testId={testId} />
      </Popover>
      <Typography.Text type="secondary">{description}</Typography.Text>
    </div>
  )
}

export default ColorPickerBox
