import React from "react"
import { Form, FormItemProps } from "antd"

const ThinFormItem: React.FC<FormItemProps> = (props) => {
  const { children, ...others } = props
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Form.Item {...others} className="small-gap">
      {children}
    </Form.Item>
  )
}

export default ThinFormItem
