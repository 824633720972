import React from "react"
import { FeatureAccess } from "@moodys/cre-cpm-client-apis.apis.services.auth"
import { getTestId } from "@moodys/cre-cpm.functions.testing"
import { FormInstance } from "antd"
import { CompanyForm } from "models/company-form.model"
import FeatureAccessList from "components/Admin/FeatureAccessList"

export interface FeatureAccessTabProps {
  featureAccess?: FeatureAccess[]
  form: FormInstance<CompanyForm>
  loading: boolean
}

const FeatureAccessTab: React.FC<FeatureAccessTabProps> = ({ featureAccess, form, loading }) =>
  !loading ? (
    <FeatureAccessList featureAccess={featureAccess} form={form} testId={getTestId("company")} />
  ) : null

export default FeatureAccessTab
