import { RowProps } from "antd"
import { getTestId } from "@moodys/cre-cpm.functions.testing"
import { Testable } from "@moodys/cre-cpm.models.testable"
import React, { useRef } from "react"
import { useDrag, useDrop } from "react-dnd"

const type = "DraggableBodyRow"

interface DragItem {
  index: number
}

export interface DraggableBodyRowProps extends RowProps, Testable {
  index: number
  moveRow: (dragIndex: number, hoverIndex: number) => void
}

const DraggableBodyRow = ({
  index,
  moveRow,
  style,
  testId,
  ...restProps
}: DraggableBodyRowProps): JSX.Element => {
  const ref = useRef(document.createElement("tr"))
  const [, drop] = useDrop({
    accept: type,
    collect: (monitor) => {
      const { index: dragIndex } = (monitor.getItem() || {}) as DragItem
      if (dragIndex === index) {
        return {}
      }
      return {
        isOver: monitor.isOver()
      }
    },
    drop: (item: DragItem) => {
      moveRow(item.index, index)
    }
  })
  const [, drag] = useDrag({
    type,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  })
  drop(drag(ref))

  return (
    <tr
      ref={ref}
      style={{ cursor: "move", ...style }}
      data-testid={getTestId("draggable_body_row_cp__tr", testId)}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...restProps}
    />
  )
}

export default DraggableBodyRow
