import React, { useCallback } from "react"
import { TFunction } from "i18next"
import { ColumnsType } from "antd/lib/table"
import { User, UserType } from "@moodys/cre-cpm-client-apis.apis.services.auth"
import { formatDate } from "@moodys/cre-cpm.functions.date-and-time"
import { getTestId } from "@moodys/cre-cpm.functions.testing"
import { isUserLocked } from "functions/User/Session"
import UserStatus from "components/Admin/UserStatus"
import ActionItem from "./ActionItem"

const getRole = (t: TFunction, value?: UserType) => {
  let role: string
  if (!value) return t("users.internal_user")
  switch (value) {
    case "external_user":
      role = t("users.external_user")
      break
    case "superadmin":
      role = t("users.rxd_admin")
      break
    case "company_admin":
      role = t("users.company_admin")
      break
    default:
      role = t("users.internal_user")
  }

  return role
}

const getPortfolioAccess = (t: TFunction, portfolios: string[] | undefined) =>
  portfolios && portfolios.length !== 0 ? t("shared.limited") : t("shared.full")

const getTableConfiguration = (
  isSuperAdmin: boolean,
  t: TFunction,
  locale: string,
  lockHandler: (userUuid: number, lock: boolean, companyUuid: string) => void,
  hasLowerAdmPermissionsThan: (user: User) => boolean
): ColumnsType<User> => {
  let columns: ColumnsType<User> = []

  const userNameColumns = [
    {
      title: t("users.first_name"),
      dataIndex: ["attributes", "name"],
      key: "first_name",
      align: "left",
      sorter: true,
      ellipsis: true,
      onCell: (record: User) => ({
        "data-testid": getTestId(`user-column-first-name-${record.id}-${record.attributes?.name}`)
      })
    },
    {
      title: t("users.family_name"),
      dataIndex: ["attributes", "family_name"],
      key: "second_name",
      align: "left",
      sorter: true,
      ellipsis: true,
      onCell: (record: User) => ({
        "data-testid": getTestId(
          `user-column-second-name-${record.id}-${record.attributes?.family_name}`
        )
      })
    }
  ] as ColumnsType<User>

  const companyColumn = [
    {
      title: t("companies.company_name"),
      dataIndex: ["relationships", "company", "attributes", "name"],
      key: "company_name",
      sorter: false,
      align: "left",
      ellipsis: true,
      onCell: (record: User) => ({
        "data-testid": getTestId(
          `user-column-company-name-${record.id}-${record.relationships?.company?.attributes?.name}`
        )
      })
    }
  ] as ColumnsType<User>

  columns = columns.concat(isSuperAdmin ? userNameColumns.concat(companyColumn) : userNameColumns)

  return columns.concat([
    {
      title: t("users.email"),
      dataIndex: ["attributes", "email"],
      key: "email",
      align: "left",
      sorter: true,
      ellipsis: true,
      onCell: (record: User) => ({
        "data-testid": getTestId(`user-column-email-${record.id}-${record.attributes?.email}`)
      })
    },
    {
      title: t("users.role"),
      dataIndex: ["attributes", "user_type"],
      key: "role",
      align: "left",
      sorter: true,
      onCell: (record: User) => ({
        "data-testid": getTestId(`user-column-role-${record.id}-${record.attributes?.user_type}`)
      }),
      render: (value: UserType) => getRole(t, value)
    },
    {
      title: t("users.portfolio_access"),
      dataIndex: ["attributes", "portfolios"],
      key: "portfolios",
      align: "left",
      sorter: false,
      onCell: (record: User) => ({
        "data-testid": getTestId(
          `user-column-portfolios-${record.id}-${record.attributes?.portfolios}`
        )
      }),
      render: (portfolios: string[]) => getPortfolioAccess(t, portfolios)
    },
    {
      title: t("shared.status"),
      key: "status",
      align: "left",
      sorter: false,
      onCell: (record: User) => ({
        "data-testid": getTestId(`user-status-${record.id}-status`)
      }),
      render: (user: User) => <UserStatus user={user} />
    },
    {
      title: t("users.last_sign_in"),
      dataIndex: ["attributes", "last_sign_in_at"],
      key: "last_sign_in_at",
      align: "center",
      sorter: true,
      onCell: (record: User) => ({
        "data-testid": getTestId(
          `user-column-last-sign-in-${record.id}-${formatDate(
            record.attributes?.last_sign_in_at,
            locale
          )}`
        )
      }),
      render: (lastSignin: number) => formatDate(lastSignin, locale)
    },
    {
      title: "Action",
      align: "center",
      dataIndex: ["id"],
      render: (id: string, user: User) => (
        <ActionItem
          id={id}
          testId={id}
          actionsAllowed={!hasLowerAdmPermissionsThan(user)}
          onLock={useCallback(() => {
            return user.id && user.relationships?.company?.id
              ? lockHandler(
                  user.id ?? 0,
                  isUserLocked(user.attributes?.locked_at),
                  user.relationships?.company?.id ?? ""
                )
              : undefined
          }, [user.id, user.relationships?.company?.id, user.attributes?.locked_at])}
          isLock={isUserLocked(user.attributes?.locked_at)}
        />
      )
    }
  ] as ColumnsType<User>)
}

export default getTableConfiguration
