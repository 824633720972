import React, { useContext, useEffect } from "react"
import { Route, RouteProps } from "react-router-dom"
import { UserType } from "@moodys/cre-cpm-client-apis.apis.services.auth"
import RequireAuth from "components/Admin/RequireAuth"
import { AuthContext } from "contexts/AuthContext"
import RedirectUnauthorized from "./RedirectUnauthorized"

export interface ProtectedRouteProps extends RouteProps {
  minAccessRole?: UserType[]
  matchUserId?: boolean
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  children,
  minAccessRole = [],
  matchUserId = false,
  ...routing
}) => {
  const { ensureCookieSyncWithState } = useContext(AuthContext)

  useEffect(() => {
    ensureCookieSyncWithState()
  })

  return (
    <Route
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...routing}
      render={() => (
        <>
          <RequireAuth minAccessRole={minAccessRole} matchUserId={matchUserId} useSpinOnValidating>
            {children}
          </RequireAuth>
          <RedirectUnauthorized minAccessRole={minAccessRole} matchUserId={matchUserId} />
        </>
      )}
    />
  )
}

export default ProtectedRoute
