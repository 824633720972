import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Col, Form, Input, Row, Select } from "antd"
import moment from "moment"
import {
  CompanyFilter,
  UserFilter,
  Company,
  UserType
} from "@moodys/cre-cpm-client-apis.apis.services.auth"
import { PaginationFilters } from "@moodys/cre-cpm-client-apis.apis.api-base"
import { getTestId } from "@moodys/cre-cpm.functions.testing"
import { useQuery } from "@moodys/cre-cpm.hooks.use-query"
import RequireAuth from "components/Admin/RequireAuth"
import UserFilterTypeSelect, { UserFilterType } from "components/Admin/UserFilterTypeSelect"
import { AuthContext } from "contexts/AuthContext"
import {
  CompanyContext,
  defaultFilters,
  defaultPagination,
  defaultSortable
} from "contexts/CompanyContext"
import debounce from "functions/Timer"
import handleApiError from "functions/Exceptions"

const { Search } = Input
const { Option } = Select

export interface UserFilterProps {
  setFilters: (filter: UserFilter) => void
  setPagination: (pagination: PaginationFilters) => void
  filters: UserFilter
  loading: boolean
}

const Filter: React.FC<UserFilterProps> = ({ setFilters, setPagination, filters, loading }) => {
  const searchMinLength = 3
  const [form] = Form.useForm()
  const { t } = useTranslation("auth")
  const { getCompanies, getCompany } = useContext(CompanyContext)
  const { isSuperAdmin, isCompanyAdmin, userCompanyUuid } = useContext(AuthContext)
  const [companyFilters, setCompanyFilters] = useState<CompanyFilter>(defaultFilters)
  const [userTypeFilter, setUserTypeFilter] = useState<UserFilterType>(UserFilterType.allUsers)
  const [selectedCompany, setSelectedCompany] = useState<Company>()
  const [companyDataSource, , , , companyRequest] = useQuery<Company[] | undefined>(
    () => getCompanies(defaultPagination, companyFilters, defaultSortable),
    {
      disabled: !isSuperAdmin(),
      onError: (err) => {
        handleApiError(err)
      }
    }
  )
  useQuery<Company | undefined>(() => getCompany(userCompanyUuid), {
    disabled: !isCompanyAdmin(),
    onSuccess: (response) => {
      setSelectedCompany(response.data)
    },
    onError: (err) => {
      handleApiError(err)
    }
  })

  const toUserFilter = (type: UserFilterType): UserFilter => {
    const internalUsers = ["company_user", "company_admin"].concat(
      isSuperAdmin() ? ["superadmin"] : []
    ) as UserType[]
    const expirationUntil = moment().utc().unix()
    const expirationFutureDay = moment().utc().add("day", 1).startOf("day").unix()

    switch (type) {
      case UserFilterType.activeInternalUsers:
        return {
          ...filters,
          userType: internalUsers,
          expirationDateMax: undefined,
          lockedAtMax: null
        }
      case UserFilterType.activeExternalUsers:
        return {
          ...filters,
          userType: ["external_user"],
          expirationDateMax: { min: expirationFutureDay },
          lockedAtMax: null
        }
      case UserFilterType.expiredExternalUsers:
        return {
          ...filters,
          userType: ["external_user"],
          expirationDateMax: {
            max: expirationUntil
          },
          lockedAtMax: undefined
        }
      case UserFilterType.lockedExternalUsers:
        return {
          ...filters,
          userType: ["external_user"],
          lockedAtMax: {
            max: expirationUntil
          },
          expirationDateMax: undefined
        }
      case UserFilterType.lockedInternalUsers:
        return {
          ...filters,
          userType: internalUsers,
          lockedAtMax: { max: expirationUntil },
          expirationDateMax: undefined
        }
      default:
        return {
          ...filters,
          userType: undefined,
          expirationDateMax: undefined,
          lockedAtMax: undefined
        }
    }
  }

  const onSearch = (search = "") => {
    if (search !== filters.like && (search.length >= searchMinLength || search === "")) {
      setPagination(defaultPagination)
      setFilters({ ...filters, like: search?.trim() })
    }
  }
  const onCompanySearch = debounce((value: string) => {
    setCompanyFilters({ ...companyFilters, like: value })
  })

  const onCompanyChange = (companyUuid: string) => {
    setPagination(defaultPagination)
    setSelectedCompany(companyDataSource?.find((company) => company.id === companyUuid))
    setFilters({ ...filters, companyUuid: companyUuid === "" ? undefined : companyUuid })
  }

  const onUserFilterChange = (type: UserFilterType) => {
    setUserTypeFilter(type)
    setPagination(defaultPagination)
    setFilters(toUserFilter(type))
  }

  useEffect(() => {
    if (isSuperAdmin()) {
      companyRequest()
    }
  }, [companyFilters])

  const getOptions = () =>
    companyDataSource?.length
      ? companyDataSource.map((company) => (
          // eslint-disable-next-line react/jsx-indent
          <Option key={company.id} value={company.id}>
            {company.attributes.name}
          </Option>
        ))
      : []

  return (
    <Form
      layout="horizontal"
      form={form}
      initialValues={{ by: filters.like }}
      data-testid={getTestId("user_search_form")}
    >
      <Row>
        <Col span={8}>
          <Form.Item name="search" rules={[{ min: searchMinLength }]}>
            <Search
              placeholder={t("shared.search")}
              onSearch={onSearch}
              allowClear
              loading={loading}
              disabled={loading}
              minLength={3}
              data-testid={getTestId("user_search_input")}
            />
          </Form.Item>
        </Col>
        <Col span={7} offset={1}>
          <RequireAuth>
            <Form.Item name="companyName" rules={[{ min: searchMinLength }]}>
              <Select
                showSearch
                value={companyFilters.like}
                placeholder={t("shared.search_by_company_name")}
                defaultActiveFirstOption={false}
                showArrow
                filterOption={false}
                onSearch={onCompanySearch}
                onChange={onCompanyChange}
                notFoundContent={null}
                allowClear
                data-testid={getTestId("user_search_company_dropdown")}
              >
                {getOptions()}
              </Select>
            </Form.Item>
          </RequireAuth>
          {selectedCompany && isCompanyAdmin() && (
            <Form.Item name="userFilter">
              <UserFilterTypeSelect
                company={selectedCompany}
                onUserFilterChange={onUserFilterChange}
                selectedUserType={userTypeFilter}
              />
            </Form.Item>
          )}
        </Col>
        {selectedCompany && (
          <RequireAuth>
            <Col span={7} offset={1}>
              <Form.Item name="userFilter">
                <UserFilterTypeSelect
                  company={selectedCompany}
                  onUserFilterChange={onUserFilterChange}
                  selectedUserType={userTypeFilter}
                />
              </Form.Item>
            </Col>
          </RequireAuth>
        )}
      </Row>
    </Form>
  )
}

export default Filter
