import React from "react"
import { useTranslation } from "react-i18next"
import { Table, TablePaginationConfig } from "antd"
import { FilterValue, SorterResult, TableCurrentDataSource } from "antd/lib/table/interface"
import { Company } from "@moodys/cre-cpm-client-apis.apis.services.auth"
import { Pagination } from "@moodys/cre-cpm-client-apis.apis.api-base"
import getTableConfiguration from "./functions"

export interface CompanyListProps {
  data?: Company[]
  isLoading: boolean
  pagination: Pagination
  onTableChange: (
    tablePagination: TablePaginationConfig,
    _filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Company> | SorterResult<Company>[],
    extra: TableCurrentDataSource<Company>
  ) => void
}

const CompanyList: React.FC<CompanyListProps> = ({
  data,
  isLoading,
  pagination,
  onTableChange
}) => {
  const { t } = useTranslation("auth")
  return (
    <Table
      columns={getTableConfiguration(t)}
      dataSource={data}
      rowKey="id"
      loading={isLoading}
      size="small"
      bordered
      pagination={{
        current: pagination.page,
        pageSize: pagination.per_page,
        total: pagination.total,
        showSizeChanger: false,
        showLessItems: true,
        // Thats not good idea because always will generate a new ref
        // but is the way that antd works
        // eslint-disable-next-line react/no-unstable-nested-components
        showTotal: (total, range) => (
          <span>
            {t("shared.list.table.counter", {
              minPage: range[0],
              maxPage: range[1],
              totalSize: total
            })}
          </span>
        )
      }}
      onChange={onTableChange}
    />
  )
}

export default React.memo(CompanyList)
