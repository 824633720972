import { ProtectedRoute } from "components/Routing"
import { Routes } from "constants/shared"
import { LayoutContextProvider } from "contexts/LayoutContext"
import { UserContextProvider } from "contexts/UserContext"
import React from "react"
import { Switch } from "react-router-dom"
import EditUser from "./Users/Edit"

const CompanyRoutes: React.FC = () => (
  <ProtectedRoute minAccessRole={["company_user", "company_admin"]}>
    <LayoutContextProvider>
      <UserContextProvider>
        <Switch>
          <ProtectedRoute
            minAccessRole={["company_user", "company_admin"]}
            matchUserId
            path={`${Routes.company.users.edit}/:id`}
          >
            <EditUser />
          </ProtectedRoute>
        </Switch>
      </UserContextProvider>
    </LayoutContextProvider>
  </ProtectedRoute>
)

export default CompanyRoutes
