import { Key } from "react"
import { Envs, Regions } from "models/envs.model"

export const mailToHelp = "mailto:ma_cpm_help@moodys.com"

export const Routes = {
  root: "/",
  reset: "/reset",
  passwordRecovery: "/password-recovery",
  logout: "/logout",
  admin: {
    root: "/admin",
    companies: {
      root: "/admin/companies",
      new: "/admin/companies/new",
      edit: "/admin/companies/edit"
    },
    users: {
      root: "/admin/users",
      new: "/admin/users/new",
      edit: "/admin/users/edit"
    }
  },
  company: {
    root: "/company",
    users: {
      edit: "/company/users/edit"
    }
  },
  sso: {
    root: "/sso",
    login: "/sso/login",
    callback: "/sso/callback",
    logout: "/sso/logout"
  }
}

export enum Languages {
  german = "de",
  english = "en"
}

export type AntdFieldKey = Key | readonly Key[] | undefined

export type AntdAlignType = "left" | "center" | "right"

export const CardMinHeight = "95vh"

export type HttpVerb = "PUT" | "POST"

export enum Spacing {
  s8px = "var(--spacing-size-8)",
  s16px = "var(--spacing-size-16)",
  s24px = "var(--spacing-size-24)"
}

export enum StoreKeysList {
  portfolios = "portfolios"
}

export const currencyCodes = [
  "USD",
  "EUR",
  "GBP",
  "CAD",
  "AUD",
  "CHF",
  "SEK",
  "DKK",
  "NOK",
  "PLN",
  "CNY"
]

export const measured_by = ["m2", "f2"]

export const defaultPageSize = 25

export const isUsProdEnv: boolean =
  process.env.REACT_APP_ENV === Envs.PROD && process.env.REACT_APP_REGION === Regions.US

export const isDevEnv: boolean = process.env.REACT_APP_ENV === Envs.DEV
