import React from "react"
import { useTranslation } from "react-i18next"
import { Select } from "antd"
import { Company } from "@moodys/cre-cpm-client-apis.apis.services.auth"
import { getTestId } from "@moodys/cre-cpm.functions.testing"
import { Testable } from "@moodys/cre-cpm.models.testable"

const { Option } = Select

export enum UserFilterType {
  allUsers,
  activeInternalUsers,
  lockedInternalUsers,
  activeExternalUsers,
  expiredExternalUsers,
  lockedExternalUsers
}

export interface UserFilterTypeSelectProps extends Testable {
  company: Company
  selectedUserType: UserFilterType
  onUserFilterChange: (type: UserFilterType) => void
}

const UserFilterTypeSelect: React.FC<UserFilterTypeSelectProps> = ({
  company,
  selectedUserType,
  onUserFilterChange,
  testId
}) => {
  const { t } = useTranslation("auth")
  return (
    <Select
      defaultValue={selectedUserType}
      showArrow
      filterOption={false}
      onChange={onUserFilterChange}
      notFoundContent={null}
      data-testid={getTestId("user_user_filter_dropdown", testId)}
    >
      <Option key={UserFilterType.allUsers} value={UserFilterType.allUsers}>
        {t("users.filter.all_users.text")}
      </Option>
      <Option key={UserFilterType.activeInternalUsers} value={UserFilterType.activeInternalUsers}>
        {t("users.filter.active_internal_users.text", {
          total: company.attributes.internal_users_count,
          limit: company.attributes.internal_users_limit
        })}
      </Option>
      <Option key={UserFilterType.activeExternalUsers} value={UserFilterType.activeExternalUsers}>
        {t("users.filter.active_external_users.text", {
          total: company.attributes.external_users_count,
          limit: company.attributes.external_users_limit
        })}
      </Option>
      <Option key={UserFilterType.lockedInternalUsers} value={UserFilterType.lockedInternalUsers}>
        {t("users.filter.locked_internal_users.text")}
      </Option>
      <Option key={UserFilterType.expiredExternalUsers} value={UserFilterType.expiredExternalUsers}>
        {t("users.filter.expired_external_users.text")}
      </Option>
      <Option key={UserFilterType.lockedExternalUsers} value={UserFilterType.lockedExternalUsers}>
        {t("users.filter.locked_external_users.text")}
      </Option>
    </Select>
  )
}

export default UserFilterTypeSelect
