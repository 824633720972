import React from "react"
import { TFunction } from "i18next"
import { faBuilding, faHome, faList, faPlus, faUsers } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FeatureAccess, UserType } from "@moodys/cre-cpm-client-apis.apis.services.auth"
import { Routes } from "constants/shared"

export enum SubMenuKeys {
  companies = "companies",
  users = "users"
}

export enum MenuKeys {
  allCompanies = "allCompanies",
  newCompanies = "newCompanies",
  allUsers = "allUsers",
  newUser = "newUser"
}

export interface BaseMenuItemProps {
  key: string
  title: string | React.ReactNode
  permissions?: FeatureAccess[]
  allowedRoles?: UserType[]
  blankPage?: boolean
  icon?: JSX.Element
}
export interface MenuItemProps extends BaseMenuItemProps {
  to: string
}
export interface SubMenuItemProps extends BaseMenuItemProps {
  onTitleClick?: () => void
  items?: MenuItemProps[]
  to?: string
  isNotClickable?: boolean
}

export const getConfigurationBottom = (t: TFunction): SubMenuItemProps[] => [
  {
    key: "backbutton",
    to: process.env.REACT_APP_REDIRECT_URL ?? "",
    icon: <FontAwesomeIcon icon={faHome} />,
    title: t("shared.goback.cre.link").toUpperCase()
  }
]

const getConfigurationTop = (
  t: TFunction,
  onSubmenuClick: (key: SubMenuKeys) => () => void
): SubMenuItemProps[] => [
  {
    key: SubMenuKeys.companies,
    icon: <FontAwesomeIcon icon={faBuilding} />,
    title: t("shared.companies"),
    onTitleClick: onSubmenuClick(SubMenuKeys.companies),
    allowedRoles: ["superadmin"],
    items: [
      {
        key: MenuKeys.allCompanies,
        to: Routes.admin.companies.root,
        title: t("toolbar.all_companies"),
        icon: <FontAwesomeIcon icon={faList} />,
        allowedRoles: ["superadmin"]
      },
      {
        key: MenuKeys.newCompanies,
        to: Routes.admin.companies.new,
        title: t("toolbar.add_new_company"),
        icon: <FontAwesomeIcon icon={faPlus} />,
        allowedRoles: ["superadmin"]
      }
    ]
  },
  {
    key: SubMenuKeys.users,
    icon: <FontAwesomeIcon icon={faUsers} />,
    title: t("shared.users"),
    onTitleClick: onSubmenuClick(SubMenuKeys.users),
    allowedRoles: ["superadmin", "company_admin"],
    items: [
      {
        key: MenuKeys.allUsers,
        to: Routes.admin.users.root,
        title: t("toolbar.all_users"),
        icon: <FontAwesomeIcon icon={faList} />,
        allowedRoles: ["superadmin", "company_admin"]
      },
      {
        key: MenuKeys.newUser,
        to: Routes.admin.users.new,
        title: t("toolbar.add_new_user"),
        icon: <FontAwesomeIcon icon={faPlus} />,
        allowedRoles: ["superadmin", "company_admin"]
      }
    ]
  }
]

export default getConfigurationTop
