import { Table, TableProps } from "antd"
import { getTestId } from "@moodys/cre-cpm.functions.testing"
import { Testable } from "@moodys/cre-cpm.models.testable"
import React from "react"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import withScrolling from "react-dnd-scrolling"
import DraggableBodyRow, { DraggableBodyRowProps } from "./DraggableBodyRow"
import "./index.less"

const ScrollingComponent = withScrolling("div")

export interface DraggableTableRowProps extends Testable {
  key: React.Key
  index: number
  enabled: boolean
}

export interface DraggableTableProps<T extends DraggableTableRowProps>
  extends TableProps<T>,
    Testable {
  onItemSelect: (keys: React.Key[]) => void
  onSort: (dragIndex: number, hoverIndex: number) => void
  listSelectedKeys: React.Key[]
  height?: string | number
}

export const DraggableTable: <T extends DraggableTableRowProps>(
  args: DraggableTableProps<T>
) => JSX.Element = ({
  dataSource,
  onItemSelect,
  listSelectedKeys,
  onSort,
  height = 195,
  testId,
  ...restProps
}) => {
  const components = {
    body: {
      row: DraggableBodyRow
    }
  }

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[]) => {
      onItemSelect(selectedRowKeys)
    },
    selectedRowKeys: listSelectedKeys
  }

  const moveRow = (dragIndex: number, hoverIndex: number) => {
    onSort(dragIndex, hoverIndex)
  }

  return (
    <div className="rxd-draggable-table" data-testid={getTestId("draggable-table-cp", testId)}>
      <DndProvider backend={HTML5Backend}>
        <ScrollingComponent
          className="rxd-draggable-table-scrollable-zone"
          style={{ height }}
          data-testid={getTestId("draggable-table-cp__scrolling", testId)}
        >
          <Table
            size="small"
            dataSource={dataSource}
            rowSelection={rowSelection}
            components={components}
            data-testid={getTestId("draggable_table_cp__table", testId)}
            rowClassName={(record) => (!record.enabled ? "rxd-disabled-row" : "")}
            onRow={(_record, index) => {
              return {
                index,
                moveRow,
                testId
              } as DraggableBodyRowProps
            }}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...restProps}
          />
        </ScrollingComponent>
      </DndProvider>
    </div>
  )
}

export default DraggableTable
