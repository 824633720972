import moment from "moment"
import {
  Company,
  CompanyAttrNames,
  FeatureAccess,
  User,
  UserAttributesNames,
  UserType,
  DynamicLayout
} from "@moodys/cre-cpm-client-apis.apis.services.auth"
import { HttpVerb } from "constants/shared"
import initialCompany from "contexts/initializers/company"
import initialUser from "contexts/initializers/user"
import { CompanyForm } from "models/company-form.model"
import { UserForm } from "models/user-form.model"
import { AllKey } from "pages/Admin/Users/Edit/function"

const isNotAllowedOnPut = ["measured_by", "currency_code"]

const setupExpirationDate = (expirationDate: moment.Moment) =>
  expirationDate.hour(23).minute(59).second(59).millisecond(0).unix()

export const populateCompanyfromForm = (
  operation: HttpVerb,
  formData: CompanyForm,
  id?: string
): Company => {
  const company = {
    id,
    type: initialCompany.type,
    attributes: {}
  }

  const attributes: CompanyAttrNames[] = Object.keys(
    initialCompany.attributes
  ) as CompanyAttrNames[]

  attributes.forEach((attr: CompanyAttrNames) => {
    const value = formData[attr]
    if (value) {
      if (attr === "layouts") {
        company.attributes[attr] = JSON.parse(value) as DynamicLayout
      } else if (attr === "loan_sensitivity_settings") {
        company.attributes[attr] = { ...value }
      } else if (
        operation === "POST" ||
        (operation === "PUT" && !isNotAllowedOnPut.includes(attr))
      ) {
        company.attributes[attr] = value
      }
    }
  })

  return company as Company
}

export const populateUserFromForm = (
  formData: UserForm,
  ownUserType: UserType,
  ownCompany: string,
  hasAnyFeatureAccess: (featureAccess: FeatureAccess[]) => boolean,
  id?: number
): User => {
  const user = {
    id,
    type: initialUser.type,
    attributes: {},
    relationships: {
      company: {
        // TODO: consistance, we should have company or companies, just one of them for GET and PUT/POST
        type: initialCompany.type,
        attributes: {
          company_ref_uuid: ""
        }
      }
    }
  }
  const userType = !formData.user_type ? ownUserType : formData.user_type

  const isScalatingSuperadminPrivilegies = userType === "superadmin" && ownUserType !== "superadmin"
  const isScalatingCompanyAdminPrivilegies =
    userType === "company_admin" && ownUserType !== "company_admin" && ownUserType !== "superadmin"
  const isScalatingToAdminPrivilegies =
    ownUserType !== "superadmin" && ownUserType !== "company_admin" && userType !== ownUserType

  const isScalatingPrivilegies =
    // Just superadmin could setup superadmin roles
    isScalatingSuperadminPrivilegies ||
    // Just admins (company and super) could setup company admins
    isScalatingCompanyAdminPrivilegies ||
    // Non admin users just can setup its own role
    isScalatingToAdminPrivilegies

  // API will reset this field when user type is non external
  const shouldDeleteExternalExpirationDateField = (attr: UserAttributesNames): boolean =>
    userType !== "external_user" && attr === "expiration_date"

  const shouldNotIncludeEmail = (attr: UserAttributesNames): boolean => !!id && attr === "email"

  const shouldNotIncludeWithoutFeatureAccess = (attr: UserAttributesNames): boolean =>
    (ownUserType === "company_user" || ownUserType === "external_user") &&
    !hasAnyFeatureAccess(["unit_conversions"]) &&
    (attr === "currency_code" || attr === "measured_by")

  const attributes = Object.keys(initialUser.attributes) as UserAttributesNames[]

  // When add airbrake sent to handleError to register this
  // BCK should always check this
  if (isScalatingPrivilegies) throw new Error("Your role can not scalate over your own role")

  attributes.forEach((attr) => {
    if (
      shouldDeleteExternalExpirationDateField(attr) ||
      shouldNotIncludeEmail(attr) ||
      shouldNotIncludeWithoutFeatureAccess(attr)
    ) {
      user.attributes[attr] = undefined
    } else if (id && attr === "email") {
      user.attributes[attr] = undefined
    } else if (attr === "expiration_date" && userType === "external_user") {
      user.attributes[attr] = setupExpirationDate(formData.expiration_date)
    } else if (attr === "locked_at" && formData.locked_at !== undefined) {
      user.attributes[attr] = formData.locked_at ? moment().utc().unix() : null
    } else if (attr === "portfolios" && formData.portfolios !== undefined) {
      const normPortfolios = (formData.portfolios ?? []).includes(AllKey) ? [] : formData.portfolios
      user.attributes[attr] =
        ownUserType === "company_admin" || ownUserType === "superadmin"
          ? normPortfolios ?? []
          : undefined
    } else {
      user.attributes[attr] = formData[attr]
    }
  })

  user.relationships.company.attributes.company_ref_uuid =
    ownUserType === "company_admin" || ownUserType === "company_user"
      ? ownCompany
      : formData.company_uuid ?? ownCompany

  return user as User
}
