import { useState } from "react"
import { TableCurrentDataSource, TablePaginationConfig } from "antd/lib/table/interface"
import { Pagination, PaginationFilters } from "@moodys/cre-cpm-client-apis.apis.api-base"
import { processPagination } from "functions/Table"

const usePagination = <T>(
  initialPagination: PaginationFilters
): [
  PaginationFilters,
  (pagination: PaginationFilters) => void,
  (tablePagination: TablePaginationConfig, extra: TableCurrentDataSource<T>) => void,
  Pagination,
  (paginationResponse: Pagination) => void
] => {
  const [pagination, setPagination] = useState(initialPagination)
  const [paginationResponse, setPaginationResponse] = useState<Pagination>({
    page: initialPagination.number,
    per_page: initialPagination.size,
    total: 0,
    total_pages: 0
  })

  const onPagination = (
    tablePagination: TablePaginationConfig,
    extra: TableCurrentDataSource<T>
  ) => {
    processPagination(extra, tablePagination, pagination, setPagination)
  }

  return [pagination, setPagination, onPagination, paginationResponse, setPaginationResponse]
}

export default usePagination
