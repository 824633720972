import { currencyCodes } from "constants/shared"
import authI18n from "functions/I18n"

const getCurrencyCodesWithName = () =>
  currencyCodes.map((code) => ({
    currency: code,
    name: authI18n.t(`shared.currencies.${code}.text`)
  }))

export default getCurrencyCodesWithName
