import { getTestId } from "@moodys/cre-cpm.functions.testing"
import { Testable } from "@moodys/cre-cpm.models.testable"
import React from "react"
import { Link as LinkReactRouter, LinkProps } from "react-router-dom"

const Link: React.FC<LinkProps & React.RefAttributes<HTMLAnchorElement> & Testable> = ({
  to,
  replace,
  children,
  testId,
  ...anchorProps
}) =>
  typeof to === "string" && /^(http:|https:|mailto:)/.test(to.toString()) ? (
    // Disabled because props spreaded are and just only are HTMLAnchorElement that are valid for <a>
    // eslint-disable-next-line react/jsx-props-no-spreading
    <a href={to} {...anchorProps} data-testid={getTestId("rxd_link", testId)}>
      {children}
    </a>
  ) : (
    <LinkReactRouter
      to={to}
      replace={replace}
      data-testid={getTestId("rxd_link", testId)}
      // Disabled because props spreaded are and just only are HTMLAnchorElement that are valid for <Link>
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...anchorProps}
    >
      {children}
    </LinkReactRouter>
  )
export default Link
