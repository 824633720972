import React from "react"
import ReactDOM from "react-dom"
import App from "App"
import "./index.less"

const renderApp = () => {
  const element = document.getElementById("auth-login-container")

  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    element
  )
}

renderApp()
