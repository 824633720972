import React, { useContext, useEffect } from "react"
import { useHistory } from "react-router-dom"

import { MapSSO } from "@moodys-ma-platform/auth-sdk"
import { removeAllCookies } from "@moodys/cre-cpm.functions.cookies"

import { invalidateSsoSession } from "functions/User/Session"
import { AuthContext } from "contexts/AuthContext"
import { AppContext } from "contexts/AppContext"
import SsoLoader from "./components/SsoLoader"

interface SsoLoginProps {}

const getRedirectUri = (callback: string) => {
  const baseUri = `${window.location.origin}/sso/callback`
  if (callback) {
    return `${baseUri}?callback=${callback}`
  }
  return baseUri
}

const doSignIn = async (sso: MapSSO, redirectUri: string, history: any) => {
  await sso.start()
  if (!(await sso.isAuthenticated())) {
    await sso.signInWithRedirect({
      state: `${redirectUri}`,
      scope:
        "openid email profile rxd_feature_access rxd_user_type rxd_region rxd_company_ref_uuid rxd_currency_code rxd_user_id rxd_company_id rxd_portfolio_access rxd_user_firstName rxd_measured_by rxd_language"
    })
  } else {
    history.push(redirectUri)
  }
}

const SsoLogin: React.FC<SsoLoginProps> = () => {
  const { callback } = useContext(AppContext)
  const history = useHistory()
  const redirectUri = getRedirectUri(callback)
  const { sso } = useContext(AuthContext)

  useEffect(() => {
    if (sso) {
      invalidateSsoSession(sso)
      removeAllCookies()
      doSignIn(sso, redirectUri, history)
    } else {
      throw new Error("SSO SDK is not available")
    }
  }, [])

  return <SsoLoader />
}

export default SsoLogin
