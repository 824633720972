import { ProtectedRoute } from "components/Routing"
import { Routes } from "constants/shared"
import { PortfolioStoreContextProvider } from "contexts/StoreProvider"
import { CompanyContextProvider } from "contexts/CompanyContext"
import { LayoutContextProvider } from "contexts/LayoutContext"
import { PlatformContextProvider } from "contexts/PlatformContext"
import { UserContextProvider } from "contexts/UserContext"
import Companies from "pages/Admin/Companies"
import Users from "pages/Admin/Users"
import React from "react"
import { Redirect, Switch } from "react-router-dom"
import EditCompany from "./Companies/Edit"
import EditUser from "./Users/Edit"

const AdminRoutes: React.FC = () => (
  <ProtectedRoute minAccessRole={["company_admin"]}>
    <LayoutContextProvider>
      <CompanyContextProvider>
        <UserContextProvider>
          <PlatformContextProvider>
            <Switch>
              <ProtectedRoute path={Routes.admin.companies.root}>
                <ProtectedRoute exact path={Routes.admin.companies.root}>
                  <Companies />
                </ProtectedRoute>
                <ProtectedRoute exact path={Routes.admin.companies.new}>
                  <EditCompany />
                </ProtectedRoute>
                <ProtectedRoute exact path={`${Routes.admin.companies.edit}/:id`}>
                  <EditCompany />
                </ProtectedRoute>
              </ProtectedRoute>
              <ProtectedRoute path="/admin/users" minAccessRole={["company_admin"]}>
                <PortfolioStoreContextProvider>
                  <ProtectedRoute
                    exact
                    path={Routes.admin.users.root}
                    minAccessRole={["company_admin"]}
                  >
                    <Users />
                  </ProtectedRoute>
                  <ProtectedRoute
                    exact
                    path={Routes.admin.users.new}
                    minAccessRole={["company_admin"]}
                  >
                    <EditUser />
                  </ProtectedRoute>
                  <ProtectedRoute
                    exact
                    path={`${Routes.admin.users.edit}/:id`}
                    minAccessRole={["company_admin"]}
                  >
                    <EditUser />
                  </ProtectedRoute>
                </PortfolioStoreContextProvider>
              </ProtectedRoute>
              <Redirect to={Routes.admin.users.root} />
            </Switch>
          </PlatformContextProvider>
        </UserContextProvider>
      </CompanyContextProvider>
    </LayoutContextProvider>
  </ProtectedRoute>
)

export default AdminRoutes
