import { Col, Form, FormInstance, Input, Row, Space } from "antd"
import React from "react"
import { useTranslation } from "react-i18next"
import {
  Company,
  LoanSensitivitySettings,
  LoanSensitivitySettingsRange
} from "@moodys/cre-cpm-client-apis.apis.services.auth"
import { CompanyForm } from "models/company-form.model"
import { getTestId } from "@moodys/cre-cpm.functions.testing"
import SensitivityBar from "./SensitivityBar"

export interface SensitivityTabProps {
  company?: Company
  form: FormInstance<CompanyForm>
}

export type SensitivityBarMode = keyof LoanSensitivitySettings

const SensitivityTab: React.FC<SensitivityTabProps> = ({ company, form }) => {
  const { t } = useTranslation("auth")

  const handleChange = (mode: SensitivityBarMode) => (range: LoanSensitivitySettingsRange) => {
    form.setFieldsValue({
      loan_sensitivity_settings: {
        ...company?.attributes?.loan_sensitivity_settings,
        [mode]: range
      }
    })
  }

  return (
    <Row>
      <Form.Item
        name="loan_sensitivity_settings"
        initialValue={company?.attributes?.loan_sensitivity_settings}
        className="rxd-auth-hidden"
      >
        <Input type="hidden" data-testid={getTestId("loan_sensitivity_settings_cp__hidden")} />
      </Form.Item>
      <Col span={24}>
        <Space className="rxd-full-width" direction="vertical" size={70}>
          <Row>
            <Col span={24}>
              <p>{t("companies.sensitivity.description")}</p>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <SensitivityBar
                mode="dscr"
                range={company?.attributes.loan_sensitivity_settings?.dscr}
                onChange={handleChange("dscr")}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <SensitivityBar
                mode="ltv"
                range={company?.attributes.loan_sensitivity_settings?.ltv}
                onChange={handleChange("ltv")}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <SensitivityBar
                mode="debt_yield"
                range={company?.attributes.loan_sensitivity_settings?.debt_yield}
                onChange={handleChange("debt_yield")}
              />
            </Col>
          </Row>
        </Space>
      </Col>
    </Row>
  )
}

export default SensitivityTab
