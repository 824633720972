/* eslint-disable jsx-a11y/label-has-for */
import React, { useContext, useEffect, useState } from "react"
import { Checkbox, Col, Form, FormInstance, Input, Row, Space, Typography } from "antd"
import { CheckboxChangeEvent } from "antd/lib/checkbox"
import { useTranslation } from "react-i18next"
import { FeatureAccess } from "@moodys/cre-cpm-client-apis.apis.services.auth"
import { Testable } from "@moodys/cre-cpm.models.testable"
import { getTestId } from "@moodys/cre-cpm.functions.testing"
import {
  addArrayWithoutDuplicates,
  difference,
  removeArrayByValue
} from "@moodys/cre-cpm.functions.array"
import FeatureAccessGroup from "components/Admin/FeatureAccessList/FeatureAccessGroup"
import { CheckboxValueType } from "antd/lib/checkbox/Group"
import { UserForm } from "models/user-form.model"
import { CompanyForm } from "models/company-form.model"
import { AuthContext } from "contexts/AuthContext"
import getConfiguration, {
  defaultSelectedValues,
  isFeatureAccessEnabled,
  allValues,
  hasSelectedFeaturesFromGroup,
  FeatureAccessConfigurationElement,
  FeatureAccessConfigurationId
} from "./functions"

export interface FeatureAccessProps extends Testable {
  // NOTE: if they are not provided is because its a new one
  featureAccess?: FeatureAccess[]
  form: FormInstance<UserForm | CompanyForm>
  // NOTE: If they are not provided all will be allowed
  enabledFeatureAccess?: FeatureAccess[]
  isUserAdminPage?: boolean
}

const FeatureAccessList: React.FC<FeatureAccessProps> = ({
  featureAccess,
  enabledFeatureAccess,
  form,
  testId,
  isUserAdminPage
}) => {
  const { t } = useTranslation("auth")
  const { isSuperAdmin, isCompanyAdmin } = useContext(AuthContext)

  const [selectedValues, setSelectedValues] = useState<FeatureAccess[]>(
    (featureAccess ?? defaultSelectedValues).filter((value) =>
      isFeatureAccessEnabled(value, enabledFeatureAccess)
    )
  )

  const configuration = getConfiguration(t)

  const handleChange = (id: FeatureAccessConfigurationId, values: CheckboxValueType[]) => {
    const toRemove = difference(configuration[id].features, values as string[]) as FeatureAccess[]
    const newSelectedValues = removeArrayByValue(selectedValues, toRemove).filter((value) =>
      isFeatureAccessEnabled(value, enabledFeatureAccess)
    )

    setSelectedValues(
      addArrayWithoutDuplicates(newSelectedValues, values as string[]) as FeatureAccess[]
    )
  }

  const isGroupChecked = (id: FeatureAccessConfigurationId) => {
    const enabledFeatureAccessForGroup = (configuration[id].features as FeatureAccess[]).filter(
      (value) => isFeatureAccessEnabled(value, enabledFeatureAccess)
    )

    return (
      difference(enabledFeatureAccessForGroup, selectedValues).length === 0 &&
      hasSelectedFeaturesFromGroup(selectedValues, enabledFeatureAccessForGroup)
    )
  }

  const handleGroupChange = (id: FeatureAccessConfigurationId, checked: boolean) => {
    if (checked) {
      const newSelectedValues = selectedValues
        .concat(difference(configuration[id].features, selectedValues) as FeatureAccess[])
        .filter((value) => isFeatureAccessEnabled(value, enabledFeatureAccess))
      setSelectedValues(newSelectedValues)
    } else if (isGroupChecked(id)) {
      setSelectedValues(removeArrayByValue(selectedValues, configuration[id].features))
    }
  }

  const isAllGroupChecked = (): boolean =>
    enabledFeatureAccess
      ? difference(enabledFeatureAccess, selectedValues).length === 0 && selectedValues.length !== 0
      : Object.keys(configuration).filter(
          (key) => difference(configuration[key].features, selectedValues).length !== 0
        ).length === 0 && selectedValues.length !== 0

  const handleSelectAll = (ev: CheckboxChangeEvent) => {
    if (!isAllGroupChecked()) {
      setSelectedValues(enabledFeatureAccess ?? allValues)
    } else if (!ev.target.checked) {
      setSelectedValues([])
    }
  }

  useEffect(() => {
    form.setFieldsValue({
      feature_access: selectedValues
    })
    // eslint-disable-next-line
  }, [selectedValues])

  useEffect(() => {
    setSelectedValues(
      (featureAccess ?? defaultSelectedValues).filter((value) =>
        isFeatureAccessEnabled(value, enabledFeatureAccess)
      )
    )
  }, [featureAccess, enabledFeatureAccess])

  const renderFeatureAccessGroup = (
    key: FeatureAccessConfigurationId,
    values: FeatureAccess[],
    onChange: (id: FeatureAccessConfigurationId, values: CheckboxValueType[]) => void,
    isChecked: (id: FeatureAccessConfigurationId) => boolean,
    onGroupChange: (id: FeatureAccessConfigurationId, checked: boolean) => void
  ) => {
    const config: FeatureAccessConfigurationElement = configuration[key]

    return (
      <FeatureAccessGroup
        title={config.title}
        options={config.features.map((feature) => ({
          label: t(`companies.features.${feature}.text`),
          value: feature,
          disabled: !isFeatureAccessEnabled(feature, enabledFeatureAccess)
        }))}
        values={values.filter((value) => config.features.includes(value))}
        onChange={onChange}
        groupChecked={isChecked(config.id)}
        onChangeGroup={onGroupChange}
        id={config.id}
        testId={config.id}
      />
    )
  }

  return (
    <Row>
      <Col span={24}>
        <Space direction="vertical" size="middle" className="rxd-full-width">
          <Form.Item name="feature_access" initialValue={featureAccess} className="rxd-auth-hidden">
            <Input
              type="hidden"
              data-testid={getTestId("feature_access_cp__hidden--feature-access", testId)}
            />
          </Form.Item>
          <Space direction="vertical" size="large" className="rxd-full-width">
            <Row>
              <Space direction="horizontal">
                <Checkbox
                  name="selectAll"
                  checked={isAllGroupChecked()}
                  onChange={handleSelectAll}
                  data-testid={getTestId("feature-access-cp__checkbox--select-all")}
                />
                <label htmlFor="selectAll">
                  <Typography.Text strong>{t("shared.select_all")}</Typography.Text>
                </label>
              </Space>
            </Row>
            <Row gutter={[8, 0]}>
              <Col span={7}>
                {renderFeatureAccessGroup(
                  "businessIntelligence",
                  selectedValues,
                  handleChange,
                  isGroupChecked,
                  handleGroupChange
                )}
              </Col>
              <Col span={7}>
                <Space direction="vertical" size="middle">
                  {renderFeatureAccessGroup(
                    "dataOperations",
                    selectedValues,
                    handleChange,
                    isGroupChecked,
                    handleGroupChange
                  )}
                  {(isSuperAdmin() || (isCompanyAdmin() && isUserAdminPage)) &&
                    renderFeatureAccessGroup(
                      "alerts",
                      selectedValues,
                      handleChange,
                      isGroupChecked,
                      handleGroupChange
                    )}
                </Space>
              </Col>
              <Col span={4}>
                {renderFeatureAccessGroup(
                  "map",
                  selectedValues,
                  handleChange,
                  isGroupChecked,
                  handleGroupChange
                )}
              </Col>
              <Col span={6}>
                <Space direction="vertical" size="middle">
                  {renderFeatureAccessGroup(
                    "newsDatabase",
                    selectedValues,
                    handleChange,
                    isGroupChecked,
                    handleGroupChange
                  )}
                  {renderFeatureAccessGroup(
                    "portfolioAssistant",
                    selectedValues,
                    handleChange,
                    isGroupChecked,
                    handleGroupChange
                  )}
                  {renderFeatureAccessGroup(
                    "ews",
                    selectedValues,
                    handleChange,
                    isGroupChecked,
                    handleGroupChange
                  )}
                </Space>
              </Col>
            </Row>
            <Row gutter={[8, 0]}>
              <Col span={7}>
                {renderFeatureAccessGroup(
                  "tenants",
                  selectedValues,
                  handleChange,
                  isGroupChecked,
                  handleGroupChange
                )}
              </Col>

              <Col span={7}>
                <Space direction="vertical" size="middle">
                  {renderFeatureAccessGroup(
                    "dealsPipeline",
                    selectedValues,
                    handleChange,
                    isGroupChecked,
                    handleGroupChange
                  )}
                  {renderFeatureAccessGroup(
                    "favouriteComps",
                    selectedValues,
                    handleChange,
                    isGroupChecked,
                    handleGroupChange
                  )}
                  {renderFeatureAccessGroup(
                    "unitConversions",
                    selectedValues,
                    handleChange,
                    isGroupChecked,
                    handleGroupChange
                  )}
                </Space>
              </Col>
              <Col span={4}>
                {renderFeatureAccessGroup(
                  "quickSight",
                  selectedValues,
                  handleChange,
                  isGroupChecked,
                  handleGroupChange
                )}
              </Col>
              <Col span={6}>
                {renderFeatureAccessGroup(
                  "moreTools",
                  selectedValues,
                  handleChange,
                  isGroupChecked,
                  handleGroupChange
                )}
              </Col>
            </Row>
            <Row gutter={[8, 0]}>
              <Col span={7}>
                {renderFeatureAccessGroup(
                  "dataIngestion",
                  selectedValues,
                  handleChange,
                  isGroupChecked,
                  handleGroupChange
                )}
              </Col>
              <Col span={7}>
                {renderFeatureAccessGroup(
                  "loans",
                  selectedValues,
                  handleChange,
                  isGroupChecked,
                  handleGroupChange
                )}
              </Col>
              <Col span={7}>
                {renderFeatureAccessGroup(
                  "plausibilityCheck",
                  selectedValues,
                  handleChange,
                  isGroupChecked,
                  handleGroupChange
                )}
              </Col>
              <Col span={14} />
            </Row>
          </Space>
        </Space>
      </Col>
    </Row>
  )
}

export default React.memo(FeatureAccessList)
