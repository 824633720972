import {
  SorterResult,
  TableCurrentDataSource,
  TablePaginationConfig
} from "antd/lib/table/interface"
import { Sortable, PaginationFilters } from "@moodys/cre-cpm-client-apis.apis.api-base"
import { last } from "@moodys/cre-cpm.functions.array"
import { SortableMapping } from "components/Admin/hooks/useSortable"
import { AntdFieldKey } from "constants/shared"

export const processPagination = <T>(
  extra: TableCurrentDataSource<T>,
  tablePagination: TablePaginationConfig,
  pagination?: PaginationFilters,
  setPagination?: (pagination: PaginationFilters) => void
) => {
  if (extra.action === "paginate" && setPagination && pagination) {
    const currentPage = {
      number: tablePagination.current,
      size: tablePagination.pageSize
    } as PaginationFilters
    setPagination(currentPage)
  }
}

export const processSorting = <T>(
  extra: TableCurrentDataSource<T>,
  sorter: SorterResult<T> | SorterResult<T>[],
  sorting?: Sortable,
  setSortable?: (sortable: Sortable) => void,
  extraMapping?: SortableMapping
) => {
  if (extra.action === "sort" && sorting && setSortable) {
    const currSort = last(sorter)
    const field = last<AntdFieldKey>(currSort?.field) as string
    const mappedFieldName = extraMapping && extraMapping[currSort?.columnKey ?? ""]
    setSortable({
      fieldName: currSort?.order ? mappedFieldName ?? field : "",
      fieldOrder: currSort?.order
    })
  }
}
