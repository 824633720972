import React, { useContext, useEffect } from "react"

import { AuthContext } from "contexts/AuthContext"
import { invalidateSsoSession } from "functions/User/Session"
import SsoLoginRedirect from "./components/SsoLoginRedirect"

interface SsoLogoutProps {}

const SsoLogout: React.FC<SsoLogoutProps> = () => {
  const { sso } = useContext(AuthContext)

  useEffect(() => {
    invalidateSsoSession(sso)
  }, [])

  return <SsoLoginRedirect />
}

export default SsoLogout
