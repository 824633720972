import React, { useCallback, useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import moment from "moment"
import { Col, Row, TablePaginationConfig, Typography } from "antd"
import { FilterValue, SorterResult, TableCurrentDataSource } from "antd/lib/table/interface"
import { User } from "@moodys/cre-cpm-client-apis.apis.services.auth"
import { getTestId } from "@moodys/cre-cpm.functions.testing"
import usePagination from "components/Admin/hooks/usePagination"
import useSortable from "components/Admin/hooks/useSortable"
import useUpdateMenu from "components/Admin/hooks/useUpdateMenu"
import ExportButton from "components/Admin/TableActions/ExportButton"
import NavButton from "components/Admin/TableActions/NavButton"
import { Routes } from "constants/shared"
import { AppContext } from "contexts/AppContext"
import { AuthContext } from "contexts/AuthContext"
import {
  defaultFilters,
  defaultPagination,
  defaultSortable,
  UserContext
} from "contexts/UserContext"
import { useQuery } from "@moodys/cre-cpm.hooks.use-query"
import { showSaveSuccessMessage } from "functions/Messages"
import FeatureFlags from "components/FeatureFlag/FeatureFlag"
import handleApiError from "functions/Exceptions"
import Layout from "../Layout"
import { MenuKeys, SubMenuKeys } from "../Menu/functions"
import Filter from "./Filter"
import List from "./List"
import "./index.less"

const Users: React.FC = () => {
  const { exportUrl, getUsers, lockUser } = useContext(UserContext)
  const { t } = useTranslation("auth")
  const { locale } = useContext(AppContext)
  const { isSuperAdmin, hasLowerAdmPermissionsThan } = useContext(AuthContext)
  useUpdateMenu(SubMenuKeys.users, MenuKeys.allUsers)
  const [pagination, setPagination, onPagination, paginationResponse, setPaginationResponse] =
    usePagination(defaultPagination)
  const [sortable, onSorting] = useSortable<User>(defaultSortable)
  const [lockUserData, setLockUserData] = useState<{
    userUuid: number
    companyUuid: string
    lockedAt: number | null
  }>()
  const [filters, setFilters] = useState(defaultFilters)
  const [data, isLoading, , , getUsersRequest, setData] = useQuery(
    () => getUsers(pagination, filters, sortable),
    {
      disabled: true,
      onSuccess: (response) => {
        if (response.pagination) {
          setPagination({
            number: response.pagination.page,
            size: response.pagination.per_page
          })
          setPaginationResponse(response.pagination)
        }
      },
      onError: (err) => {
        handleApiError(err)
      }
    }
  )

  const updateUserLock = (userUuid: number, lock: number | null) => {
    const newData = data?.map((user) =>
      user.id === userUuid
        ? { ...user, attributes: { ...user.attributes, locked_at: lock } }
        : { ...user }
    )
    setData(newData)
  }

  const [, , , , lockHanlderRequest] = useQuery(
    () =>
      !lockUserData
        ? Promise.reject()
        : lockUser(lockUserData.userUuid, lockUserData.lockedAt, lockUserData.companyUuid),

    {
      disabled: true,
      onSuccess: () => {
        showSaveSuccessMessage()
        if (lockUserData) {
          updateUserLock(lockUserData.userUuid, lockUserData.lockedAt)
        }
      },
      onError: (err) => {
        handleApiError(err)
      },
      onComplete: () => {
        setLockUserData(undefined)
      }
    }
  )

  const onTableChange = useCallback(
    (
      tablePagination: TablePaginationConfig,
      _filters: Record<string, FilterValue | null>,
      sorter: SorterResult<User> | SorterResult<User>[],
      extra: TableCurrentDataSource<User>
    ) => {
      onPagination(tablePagination, extra)
      onSorting(sorter, extra)
    },
    [pagination, data]
  )

  const onLockHandler = (userUuid: number, lock: boolean, companyUuid: string) => {
    const lockedAt = !lock ? moment().utc().unix() : null
    setLockUserData({ userUuid, companyUuid, lockedAt })
  }

  useEffect(() => {
    if (lockUserData) {
      lockHanlderRequest()
    }
    // eslint-disable-next-line
  }, [lockUserData])

  useEffect(() => {
    getUsersRequest()
    // eslint-disable-next-line
  }, [pagination.number, pagination.size, filters, sortable])

  return (
    <Layout>
      <Row>
        <Col>
          <Typography.Title level={3}>
            {t(isSuperAdmin() ? "shared.admin_console" : "shared.company_admin_console")}
          </Typography.Title>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={8}>
          <Typography.Title level={4}>{t("shared.users")}</Typography.Title>
        </Col>
        <Col span={8} offset={8}>
          <Row justify="end">
            <FeatureFlags availableEnv={["development"]}>
              <ExportButton to={exportUrl} testId={getTestId("user_list__export")} />
            </FeatureFlags>
          </Row>
        </Col>
        <Col span={18}>
          <Filter
            setFilters={setFilters}
            filters={filters}
            loading={isLoading}
            setPagination={setPagination}
          />
        </Col>
        <Col span={6}>
          <Row justify="end">
            <NavButton
              to={Routes.admin.users.new}
              title={t("users.create_new_user")}
              testId={getTestId("user_list__new_user_link")}
            />
          </Row>
        </Col>
      </Row>
      <List
        data={data}
        isSuperAdmin={isSuperAdmin()}
        locale={locale}
        isLoading={isLoading}
        pagination={paginationResponse}
        onLockHandler={onLockHandler}
        hasLowerAdmPermissionsThan={hasLowerAdmPermissionsThan}
        onTableChange={onTableChange}
      />
    </Layout>
  )
}

export default Users
