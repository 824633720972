import { Col, Form, Row } from "antd"
import { FormInstance } from "antd/lib/form"
import { getTestId } from "@moodys/cre-cpm.functions.testing"
import { Testable } from "@moodys/cre-cpm.models.testable"
import React from "react"
import FormCard from "../FormCard"

export interface TwoCardFormProps<TFormModel> extends Testable {
  leftCard?: React.ReactElement<typeof FormCard>
  rightCard?: React.ReactElement<typeof FormCard>
  form: FormInstance<TFormModel>
  onFinish: (values: TFormModel) => void
}

const TwoCardForm: <T>(props: TwoCardFormProps<T>) => React.ReactElement<TwoCardFormProps<T>> = ({
  form,
  onFinish,
  leftCard,
  rightCard,
  testId
}) => (
  <Form
    layout="vertical"
    form={form}
    onFinish={onFinish}
    size="middle"
    data-testid={getTestId("twocard-cp__form", testId)}
  >
    <Row>
      <Col span={9}>{leftCard}</Col>
      <Col span={14} offset={1}>
        {rightCard}
      </Col>
    </Row>
  </Form>
)

export default TwoCardForm
