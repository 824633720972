import { Spin } from "antd"
import { AuthContext } from "contexts/AuthContext"
import React, { useContext, useEffect } from "react"

const Logout: React.FC = () => {
  const { logout } = useContext(AuthContext)
  useEffect(() => {
    logout()
  }, [])
  return (
    <div className="rxd-auth-absolute-center">
      <Spin />
    </div>
  )
}

export default Logout
