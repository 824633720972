function debounce(func: Function, timeout = 300) {
  let timer: NodeJS.Timeout
  function innerDebounce(this: Function, ...args: unknown[]): void {
    clearTimeout(timer)
    timer = setTimeout(() => {
      func.apply(this, args)
    }, timeout)
  }
  return innerDebounce
}

export default debounce
