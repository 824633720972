import {
  GeneralApiProblem,
  ApiResponseKind,
  ApiError,
  ApiException
} from "@moodys/cre-cpm-client-apis.apis.api-base"
import { showApiError, showUnexpectedError } from "functions/Messages"

const handleApiError = (
  error: Error | ApiException | GeneralApiProblem | ApiResponseKind | unknown
) => {
  let kind: ApiResponseKind
  let issues: ApiError[] | undefined

  try {
    if (error instanceof Error) {
      if (typeof (error as ApiException).getIssues === "function") {
        issues = (error as ApiException).getIssues()
      }
      kind = error.message as ApiResponseKind
    } else if (typeof error === "string") {
      kind = error as ApiResponseKind
    } else {
      kind = (error as GeneralApiProblem)?.kind
    }

    showApiError(kind, issues)
    // TODO: verify if we have to sent to relic
  } catch {
    showUnexpectedError()
    // TODO: verify if we have to sent to relic
  }
}

export default handleApiError
