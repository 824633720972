import React from "react"
import { Row, Checkbox, CheckboxOptionType, Typography, List } from "antd"
import { CheckboxChangeEvent } from "antd/lib/checkbox"
import { CheckboxValueType } from "antd/lib/checkbox/Group"
import { FeatureAccess } from "@moodys/cre-cpm-client-apis.apis.services.auth"
import { getTestId } from "@moodys/cre-cpm.functions.testing"
import { Testable } from "@moodys/cre-cpm.models.testable"
import { Spacing } from "constants/shared"
import { FeatureAccessConfigurationId } from "../functions"
import FeatureAccessCheckbox from "../FeatureAccessCheckbox"

export interface FeatureAccessGroupProps extends Testable {
  id: FeatureAccessConfigurationId
  title: string
  options: CheckboxOptionType[]
  values: FeatureAccess[]
  groupChecked?: boolean
  onChange: (id: FeatureAccessConfigurationId, selectedFeatures: CheckboxValueType[]) => void
  onChangeGroup?: (id: FeatureAccessConfigurationId, checked: boolean) => void
}

const FeatureAccessGroup: React.FC<FeatureAccessGroupProps> = ({
  id,
  title,
  options,
  values,
  groupChecked,
  testId,
  onChange,
  onChangeGroup
}) => {
  const onChangeGroupHandler = (ev: CheckboxChangeEvent) => {
    if (onChangeGroup) {
      onChangeGroup(id, ev.target.checked)
    }
  }

  const onChangeHander = (selectedValues: CheckboxValueType[]) => {
    onChange(id, selectedValues)
  }
  const shouldDisableGroup = !options.find((option) => !option.disabled)

  return options.length === 1 ? (
    <Typography.Text strong disabled={options[0].disabled}>
      <Checkbox.Group
        value={values}
        options={options}
        onChange={onChangeHander}
        disabled={options[0].disabled}
      />
    </Typography.Text>
  ) : (
    <>
      <Row data-testid={getTestId(`feature-access-group-cp`, testId)}>
        <FeatureAccessCheckbox
          name={id}
          checked={groupChecked}
          onChange={onChangeGroupHandler}
          disabled={shouldDisableGroup}
          testId={getTestId(`feature-access-group-cp__checkbox--select-all`, testId)}
          title={title}
          strongLabel
          spacingSize="small"
        />
      </Row>
      <Row>
        <Checkbox.Group value={values} onChange={onChangeHander}>
          <List
            style={{
              paddingLeft: Spacing.s16px,
              marginTop: Spacing.s8px
            }}
            data-testid={getTestId(`feature-access-group-cp___options-list`, testId)}
            dataSource={options}
            renderItem={(option) => (
              <List.Item style={{ borderBottom: "none", padding: `${Spacing.s8px} 0` }}>
                <FeatureAccessCheckbox
                  name={option.value.toString()}
                  value={option.value}
                  disabled={option.disabled}
                  testId={getTestId(
                    `feature-access-group-cp__checkbox-option--${option.value}`,
                    testId
                  )}
                  title={option.label?.toString() ?? ""}
                />
              </List.Item>
            )}
          />
        </Checkbox.Group>
      </Row>
    </>
  )
}
export default FeatureAccessGroup
