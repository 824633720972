import React from "react"
import { Col, Row, Space, Card } from "antd"
import Container from "components/Container"
import logo from "images/logo.png"

interface AuthCardProps {
  children: React.ReactElement | React.ReactElement[]
}

const AuthCard: React.FC<AuthCardProps> = ({ children }) => (
  <Container>
    <Space direction="vertical" size="large" className="login rxd-full-width">
      <Row justify="center">
        <Col style={{ width: 400 }}>
          <Card>
            <Space direction="vertical" size="large">
              <Row justify="center">
                <Col span={23}>
                  <img src={logo} alt="moody's logo" className="rxd-auth-full-width" />
                </Col>
              </Row>
              {children}
            </Space>
          </Card>
        </Col>
      </Row>
    </Space>
  </Container>
)

export default AuthCard
