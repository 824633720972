import { useState } from "react"

const useStore = (): [
  <T>(key: string) => T | undefined,
  <T>(key: string, value: T) => void,
  (key: string) => void
] => {
  const [storage, setStorage] = useState<any>(null)

  const get = (key: string) => (storage ? storage[key] : undefined)

  const set = (key: string, value: any) => {
    setStorage({ ...storage, [key]: value })
  }

  const remove = (key: string) => {
    setStorage({ ...storage, [key]: undefined })
  }

  return [get, set, remove]
}

export default useStore
