/* eslint-disable jsx-a11y/label-has-for */
import { Checkbox, Typography, Space } from "antd"
import { CheckboxProps } from "antd/lib/checkbox"
import { SpaceSize } from "antd/lib/space"
import { getTestId } from "@moodys/cre-cpm.functions.testing"
import { Testable } from "@moodys/cre-cpm.models.testable"
import React from "react"

const FeatureAccessCheckbox: React.FC<
  CheckboxProps &
    Testable & {
      title?: string
      strongLabel?: boolean
      spacingSize?: SpaceSize | [SpaceSize, SpaceSize]
    }
> = ({
  name,
  checked,
  onChange,
  disabled,
  value,
  testId,
  title,
  strongLabel,
  spacingSize = "middle"
}) => (
  <Space direction="horizontal" size={spacingSize}>
    <Checkbox
      name={name}
      checked={checked}
      onChange={onChange}
      disabled={disabled}
      data-testid={getTestId(`feature-access-group-cp__checkbox--select-all`, testId)}
      value={value}
    />
    <label htmlFor={name}>
      <Typography.Text strong={strongLabel} disabled={disabled}>
        {title}
      </Typography.Text>
    </label>
  </Space>
)

export default React.memo(FeatureAccessCheckbox)
