import { Col, Row } from "antd"
import footerLogoLarge from "images/bottom_logo_large.svg"
import footerLogoShort from "images/bottom_logo_short.svg"
import { getTestId } from "@moodys/cre-cpm.functions.testing"
import { Testable } from "@moodys/cre-cpm.models.testable"
import React from "react"
import "./index.less"

export interface FooterProps extends Testable {
  collapsed: boolean
}

const Footer: React.FC<FooterProps> = ({ collapsed, testId }) => (
  <li key="footer" data-testid={getTestId("rxd_auth_footer", testId)}>
    <Col flex="1 1 auto">
      <Row className="rxd-auth-sidebar-footer" justify={collapsed ? "center" : "start"}>
        <Col>
          <img
            src={collapsed ? footerLogoShort : footerLogoLarge}
            alt="logo"
            data-testid={getTestId("rxd_auth_footer__logo", testId)}
          />
        </Col>
      </Row>
    </Col>
  </li>
)

export default Footer
