export const allowedAppCallbacksStg = [
  "https://backoffice.stg.cpm.moodysanalytics.com",
  "https://auth.stg.cpm.moodysanalytics.com",
  "https://app.stg.cpm.moodysanalytics.com"
]

export const allowedAppCallbacksDev = [
  "https://backoffice.dev.cpm.moodysanalytics.com",
  "https://auth.dev.cpm.moodysanalytics.com",
  "https://app.dev.cpm.moodysanalytics.com"
]

export const allowedAppCallbacksLocal = ["http://localhost"]

export const allowedAppCallbacksPro = [
  "https://auth.eu.cpm.moodysanalytics.com",
  "https://auth.us.cpm.moodysanalytics.com",
  "https://app.us.cpm.moodysanalytics.com",
  "https://app.eu.cpm.moodysanalytics.com",
  "https://backoffice.eu.cpm.moodysanalytics.com",
  "https://backoffice.us.cpm.moodysanalytics.com"
]
