import { Button, Col, Form, Input, message, Row, Typography } from "antd"
import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { authApi } from "@moodys/cre-cpm-client-apis.apis.services.auth"
import { getTestId } from "@moodys/cre-cpm.functions.testing"
import Container from "components/Container"
import AuthCard from "components/AuthCard"
import { AppContext } from "contexts/AppContext"
import { passwordStrengthValidator } from "constants/passwordReset"
import ErrorAlert from "components/ErrorAlert"
import { AuthContext } from "contexts/AuthContext"

const PasswordReset = () => {
  const { t } = useTranslation("auth")
  const { login } = useContext(AuthContext)
  const { token, callback } = useContext(AppContext)
  const userId = new URLSearchParams(window.location.search).get("user_id") ?? ""
  const [showPasswordStrengthWarning, setShowPasswordStrengthWarning] = useState(false)

  useEffect(() => {
    if (!token || !userId) {
      message.error(t("passwordReset.invalidLink"))
    }
    // eslint-disable-next-line
  }, [token])

  const onFinish = ({
    password,
    reenterPassword
  }: {
    password: string
    reenterPassword: string
  }) => {
    if (password !== reenterPassword) {
      message.error(t("passwordReset.passwordsNotMatch"))
      return
    }

    if (!password.match(passwordStrengthValidator)) {
      setShowPasswordStrengthWarning(true)
    } else if (token) {
      authApi
        .updateUserPassword(password, token, parseInt(userId, 10))
        .then((response) => {
          if (response.kind === "ok" && response.data?.attributes?.email) {
            message.success(t("passwordReset.successMessage"))
            login(response.data?.attributes?.email, password, true, callback)
          } else if (
            response.kind === "rejected" &&
            response.issues?.length &&
            response.issues[0].code === "invalid_password"
          ) {
            setShowPasswordStrengthWarning(true)
          } else {
            message.error(t("passwordReset.invalidLink"))
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            message.error(t("passwordReset.invalidLink"))
          } else {
            message.error(t("shared.errors.unexpected.text"))
          }
        })
    }
  }

  const showAlert = () => {
    if (showPasswordStrengthWarning) {
      return (
        <ErrorAlert
          data-testid={getTestId("reset-password__error_list")}
          message={t("passwordReset.strengthWarningTitle")}
          description={
            <ul data-testid={getTestId("reset-error-message")}>
              <li>{t("passwordReset.strengthWarningFirstItem")}</li>
              <li>{t("passwordReset.strengthWarningSecondItem")}</li>
              <li>{t("passwordReset.strengthWarningThirdItem")}</li>
              <li>{t("passwordReset.strengthWarningFourthItem")}</li>
            </ul>
          }
        />
      )
    }
    return null
  }

  return (
    <Container>
      <AuthCard>
        <Row>
          <Col span={24} style={{ textAlign: "center", fontSize: 20 }}>
            <Typography.Title level={4}>{t("passwordRecovery.changePassword")}</Typography.Title>
          </Col>
          {showAlert()}
        </Row>
        <Row>
          <Col span={24}>
            <Form
              layout="vertical"
              onFinish={onFinish}
              requiredMark={false}
              style={{ width: "100%" }}
              data-testid={getTestId("reset-password__form")}
            >
              <Row>
                <Col span={24}>
                  <Form.Item
                    label={t("passwordRecovery.password")}
                    name="password"
                    className="password"
                    rules={[{ required: true, message: t("shared.errors.fieldRequired") }]}
                  >
                    <Input.Password
                      className="user-password-input"
                      size="large"
                      data-testid={getTestId("reset-password-input")}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    label={t("passwordRecovery.reenterPassword")}
                    name="reenterPassword"
                    className="reenter-password"
                    rules={[{ required: true, message: t("shared.errors.fieldRequired") }]}
                  >
                    <Input.Password
                      className="user-reenter-password-input"
                      size="large"
                      data-testid={getTestId("reset-reenter-password-input")}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24} style={{ textAlign: "center" }}>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      size="large"
                      className="reset-button btn-brand"
                      style={{ width: "100%" }}
                      data-testid={getTestId("reset-submit-button")}
                    >
                      {t("passwordRecovery.changeYourPassword")}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </AuthCard>
    </Container>
  )
}

export default PasswordReset
