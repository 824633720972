import { Affix, Spin } from "antd"
import React from "react"
import clsx from "clsx"
import { getTestId } from "@moodys/cre-cpm.functions.testing"
import { Testable } from "@moodys/cre-cpm.models.testable"

export interface LoadingProps extends Testable {
  loading: boolean
}

const Loading: React.FC<LoadingProps> = ({ loading, children, testId }) => (
  <>
    <Affix
      className={clsx("rxd-auth-absolute-center", !loading && "rxd-auth-hidden")}
      data-testid={getTestId("loading_cp", testId)}
    >
      <Spin />
    </Affix>
    <div className={clsx(loading && "rxd-auth-hidden")}>{children}</div>
  </>
)

export default Loading
