import { getAppEnvironment } from "@moodys/cre-cpm.functions.environment"
import {
  allowedAppCallbacksDev,
  allowedAppCallbacksLocal,
  allowedAppCallbacksPro,
  allowedAppCallbacksStg
} from "constants/security"

const hasValidAppCallback = (callback: string): boolean => {
  const env = getAppEnvironment()
  const url = new URL(callback)
  const protocalAndDomain = `${url.protocol}//${url.hostname}`

  if (!callback) return true

  switch (env) {
    case "production":
      return allowedAppCallbacksPro.includes(protocalAndDomain)
    case "staging":
      return allowedAppCallbacksStg.includes(protocalAndDomain)
    case "development":
      return (
        allowedAppCallbacksLocal.includes(protocalAndDomain) ||
        allowedAppCallbacksDev.includes(protocalAndDomain)
      )
    case "cypress":
    case "test":
      return allowedAppCallbacksLocal.includes(protocalAndDomain)
    default:
      return false
  }
}

export default hasValidAppCallback
