import React from "react"
import { useTranslation } from "react-i18next"
import { Affix, Button, FormInstance, Row, Spin } from "antd"
import { User } from "@moodys/cre-cpm-client-apis.apis.services.auth"
import { getTestId } from "@moodys/cre-cpm.functions.testing"
import FormCard from "components/Admin/FormCard"
import BasicUserForm from "components/Forms/BasicUserForm"
import { Spacing } from "constants/shared"
import { UserForm } from "models/user-form.model"

interface LeftFormProps {
  form: FormInstance<UserForm>
  loading: boolean
  saving: boolean
  user?: User
  id?: string
}

const LeftForm: React.FC<LeftFormProps> = ({ form, loading, user, id, saving }) => {
  const { t } = useTranslation("auth")
  const mainForm = () => (
    <FormCard subtitle={t("users.info")} title={t("shared.admin_console")}>
      <BasicUserForm user={user} id={id} form={form} />
      <Row justify="end">
        <Affix style={{ position: "absolute", bottom: Spacing.s16px, right: Spacing.s16px }}>
          <Button
            type="primary"
            htmlType="submit"
            disabled={saving}
            loading={saving}
            data-testid={getTestId("user_leftform__save_changes_btn")}
          >
            {id ? t("shared.save_changes") : t("companies.create_user")}
          </Button>
        </Affix>
      </Row>
    </FormCard>
  )
  return id && loading ? (
    <FormCard
      subtitle={t("companies.info")}
      title={t("shared.admin_console")}
      testId={getTestId("user_leftform__card")}
    >
      <Affix className="rxd-auth-absolute-center">
        <Spin data-testid={getTestId("user_leftform__spin")} />
      </Affix>
    </FormCard>
  ) : (
    mainForm()
  )
}

export default LeftForm
