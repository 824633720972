import { Alert, Col, Row } from "antd"
import React from "react"

interface ErrorAlertProps {
  message: React.ReactNode
  description: React.ReactNode
}

const ErrorAlert: React.FC<ErrorAlertProps> = ({ message, description }) => {
  return (
    <Row>
      <Col span={24}>
        <Alert message={message} description={description} type="error" />
      </Col>
    </Row>
  )
}

export default ErrorAlert
