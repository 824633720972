import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Col, Row, TablePaginationConfig, Typography } from "antd"
import { FilterValue, SorterResult, TableCurrentDataSource } from "antd/lib/table/interface"
import { Company } from "@moodys/cre-cpm-client-apis.apis.services.auth"
import { getTestId } from "@moodys/cre-cpm.functions.testing"
import usePagination from "components/Admin/hooks/usePagination"
import useSortable from "components/Admin/hooks/useSortable"
import useUpdateMenu from "components/Admin/hooks/useUpdateMenu"
import ExportButton from "components/Admin/TableActions/ExportButton"
import NavButton from "components/Admin/TableActions/NavButton"
import { Routes } from "constants/shared"
import {
  CompanyContext,
  defaultFilters,
  defaultPagination,
  defaultSortable
} from "contexts/CompanyContext"
import { useQuery } from "@moodys/cre-cpm.hooks.use-query"
import FeatureFlags from "components/FeatureFlag/FeatureFlag"
import handleApiError from "functions/Exceptions"
import Layout from "../Layout"
import { MenuKeys, SubMenuKeys } from "../Menu/functions"
import Filter from "./Filter"
import List from "./List"

const Companies: React.FC = () => {
  const { exportUrl, getCompanies } = useContext(CompanyContext)
  const { t } = useTranslation("auth")
  useUpdateMenu(SubMenuKeys.companies, MenuKeys.allCompanies)
  const [pagination, setPagination, onPagination, paginationResponse, setPaginationResponse] =
    usePagination(defaultPagination)
  const [sortable, onSorting] = useSortable<Company>(defaultSortable)
  const [filters, setFilters] = useState(defaultFilters)
  const [data, isLoading, , , getCompaniesRequest] = useQuery(
    () => getCompanies(pagination, filters, sortable),
    {
      disabled: true,
      onSuccess: (response) => {
        if (response.pagination) {
          setPagination({
            number: response.pagination?.page,
            size: response.pagination?.per_page
          })
          setPaginationResponse(response.pagination)
        }
      },
      onError: (err) => {
        handleApiError(err)
      }
    }
  )

  const onTableChange = (
    tablePagination: TablePaginationConfig,
    _filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Company> | SorterResult<Company>[],
    extra: TableCurrentDataSource<Company>
  ) => {
    onPagination(tablePagination, extra)
    onSorting(sorter, extra)
  }

  useEffect(() => {
    getCompaniesRequest()
    // eslint-disable-next-line
  }, [pagination.number, pagination.size, filters, sortable])

  return (
    <Layout>
      <Row>
        <Col>
          <Typography.Title level={3}>{t("shared.admin_console")}</Typography.Title>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={8}>
          <Typography.Title level={4}>{t("shared.companies")}</Typography.Title>
        </Col>
        <Col span={8} offset={8}>
          <Row justify="end">
            <FeatureFlags availableEnv={["development"]}>
              <ExportButton to={exportUrl} testId={getTestId("company_list__export_btn")} />
            </FeatureFlags>
          </Row>
        </Col>
        <Col span={8}>
          <Filter
            setFilters={setFilters}
            filters={filters}
            loading={isLoading}
            setPagination={setPagination}
          />
        </Col>
        <Col span={8} offset={8}>
          <Row justify="end">
            <NavButton
              to={Routes.admin.companies.new}
              title={t("companies.create_new_company")}
              testId={getTestId("company_list_new_link")}
            />
          </Row>
        </Col>
      </Row>
      <List
        data={data}
        isLoading={isLoading}
        pagination={paginationResponse}
        onTableChange={onTableChange}
      />
    </Layout>
  )
}

export default Companies
