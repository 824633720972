import { Col, ColProps, Row } from "antd"
import React from "react"

interface ScreenCenterContentProps {
  colWidth?: ColProps["span"]
}

const ScreenCenterContent: React.FC<ScreenCenterContentProps> = ({ colWidth = 24, children }) => {
  return (
    <Row justify="space-around" align="middle" style={{ height: "100vh" }}>
      <Col span={colWidth} style={{ textAlign: "center" }}>
        {children}
      </Col>
    </Row>
  )
}

export default ScreenCenterContent
