import React, { useContext } from "react"
import { Card, Col, Layout as LayoutAntd, Row } from "antd"
import Scrollable from "components/Admin/Scrollable"
import { CardMinHeight } from "constants/shared"
import { LayoutContext } from "contexts/LayoutContext"
import { getTestId } from "@moodys/cre-cpm.functions.testing"
import Content from "../Content"
import "./index.less"
import Menu from "../Menu"

const { Sider } = LayoutAntd
export interface LayoutProps {
  useCard?: boolean
}

const Layout: React.FC<LayoutProps> = ({ children, useCard = true }) => {
  const { isMenuCollapsed, setMenuCollapsed } = useContext(LayoutContext)

  const onSiderMouseEnterHandler = () => {
    setMenuCollapsed(false)
  }

  const onSiderMouseLeaveHandler = () => {
    setMenuCollapsed(true)
  }

  return (
    <LayoutAntd className="rxd-layout">
      <Sider
        collapsed={isMenuCollapsed}
        onMouseEnter={onSiderMouseEnterHandler}
        onMouseLeave={onSiderMouseLeaveHandler}
        data-testid={getTestId("sider")}
        width="250"
      >
        <Menu isCollapsed={isMenuCollapsed} data-testid={getTestId("menu-container")} />
      </Sider>
      <LayoutAntd>
        <div className="rxd-layout-auth-screen">
          <Scrollable height="100vh">
            <Content>
              <Row justify="center" align="middle" style={{ width: "100%" }}>
                <Col span={23}>
                  {useCard && <Card style={{ minHeight: CardMinHeight }}>{children}</Card>}
                  {!useCard && children}
                </Col>
              </Row>
            </Content>
          </Scrollable>
        </div>
      </LayoutAntd>
    </LayoutAntd>
  )
}
export default Layout
