import { DraggableTableRowProps } from "."

export const mapToDraggableTableRow = <T, U extends { key: string; enabled: boolean }>(
  // Array could come empty (thats why has default value) but the logical arg sort because the operation
  // is related with arrays is: first the array and then the mapping
  // eslint-disable-next-line default-param-last
  array: T[] = [],
  mapping: (element: T) => U
): DraggableTableRowProps[] =>
  array.map((element, ndx) => ({
    index: ndx,
    ...mapping(element)
  }))

export default mapToDraggableTableRow
