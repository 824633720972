import { Stylesheet } from "@moodys/cre-cpm-client-apis.apis.services.auth"
import { TFunction } from "i18next"

export interface ColorConfigurationProps {
  title?: string
  description?: string
  defaultColor: string
  show?: boolean
}
export interface StylesheetConfigurationProps {
  primary_color: ColorConfigurationProps
  secondary_color: ColorConfigurationProps
  primary_text_color: ColorConfigurationProps
  primary_color_sidebar: ColorConfigurationProps
  secondary_color_sidebar: ColorConfigurationProps
  third_color_sidebar: ColorConfigurationProps
  third_text_color: ColorConfigurationProps
  third_color: ColorConfigurationProps
  fourth_color: ColorConfigurationProps
}

const getConfiguration = (t: TFunction): StylesheetConfigurationProps => ({
  primary_color: {
    title: t("companies.stylesheet.primary.title"),
    description: t("companies.stylesheet.primary.description"),
    defaultColor: "#0074ff",
    show: true
  },
  secondary_color: {
    title: t("companies.stylesheet.secondary.title"),
    description: t("companies.stylesheet.secondary.description"),
    defaultColor: "#666",
    show: true
  },
  primary_text_color: {
    title: t("companies.stylesheet.primary_text.title"),
    description: t("companies.stylesheet.primary_text.description"),
    defaultColor: "#ffffff",
    show: true
  },
  primary_color_sidebar: {
    defaultColor: "#0074ff"
  },
  secondary_color_sidebar: {
    defaultColor: "#221d33"
  },
  third_color_sidebar: {
    defaultColor: "#20292f"
  },
  third_color: {
    defaultColor: "#b3cfd1"
  },
  third_text_color: {
    defaultColor: "#000"
  },
  fourth_color: {
    defaultColor: "#d5e5e7"
  }
})

const getDefaults = (config: StylesheetConfigurationProps) =>
  Object.keys(config).reduce(
    (initialState, key) => ({
      ...initialState,
      [key]: config[key].defaultColor
    }),
    {}
  ) as Stylesheet

export const getInitialState = (
  config: StylesheetConfigurationProps,
  colors?: Stylesheet | null
) => ({
  ...getDefaults(config),
  ...colors
})

export default getConfiguration
