import { redirectTo } from "@moodys/cre-cpm.functions.window-location"
import React, { useEffect } from "react"
import { useHistory } from "react-router-dom"

export interface NavigateProps {
  to: string
  state?: unknown
  search?: string
  replace?: boolean
}

const Navigate: React.FC<NavigateProps> = ({ to, state, search, replace }) => {
  const history = useHistory()

  useEffect(() => {
    if (/^(http|https):\/\//.test(to)) {
      redirectTo(to)
    } else if (replace) {
      history.replace(
        {
          pathname: to,
          search
        },
        state
      )
    } else {
      history.push(
        {
          pathname: to,
          search
        },
        state
      )
    }
  })

  return null
}

export default Navigate
