import moment from "moment"

import { removeAllCookies, removeRefreshCookie } from "@moodys/cre-cpm.functions.cookies"
import {
  isRootLocation,
  redirectTo,
  redirectToRoot
} from "@moodys/cre-cpm.functions.window-location"
import { UserType, authApi } from "@moodys/cre-cpm-client-apis.apis.services.auth"
import { MapSSO } from "@moodys-ma-platform/auth-sdk"

import platformApi from "api/services/platform"
import { isUsProdEnv } from "constants/shared"

export const invalidateSsoSession = (sso: MapSSO | null): void => {
  sso?.tokenManager.clear()
  sso?.stop()
  removeRefreshCookie()
}

// We need absolute URL to trigger a reload page and AppContext gets the callback param properly
export const createRedirectToAdminWithCallback = (
  pathname: string,
  sso: MapSSO | null = null
): string => {
  invalidateSsoSession(sso)

  return isUsProdEnv
    ? `${process.env.REACT_APP_BASE_URL}/sso/login`
    : `${process.env.REACT_APP_BASE_URL}?callback=${process.env.REACT_APP_BASE_URL}${pathname}`
}

// NOTE: we keep the redirection for non USA users. When try to login if its unsucessfully
// we should remove cookies and reset torken from API without redirect them
export const logout = async (sso: MapSSO | null, withRedirection: boolean = true) => {
  await sso?.start()
  const updateAuthState = await sso?.authStateManager.updateAuthState()
  const getAuthState = sso?.authStateManager.getAuthState()
  const isSsoAuthenticated = getAuthState?.isAuthenticated || updateAuthState?.isAuthenticated
  const tokens = sso?.tokenManager.getTokens()

  // Note: should be called only for the RXD sessions, not for SSO.
  if (withRedirection && !isSsoAuthenticated) {
    await authApi.logout()
  }

  authApi.clearToken()
  platformApi.clearToken()

  if (sso && isSsoAuthenticated) {
    await sso
      .signOut({
        postLogoutRedirectUri: isUsProdEnv
          ? `${process.env.REACT_APP_BASE_URL}/sso/login`
          : process.env.REACT_APP_BASE_URL
      })
      .then(() => {
        invalidateSsoSession(sso)
      })
  }

  removeAllCookies()

  if (tokens?.idToken?.token) {
    redirectTo(
      `${process.env.REACT_APP_SSO_ISSUER}/oauth2/v1/logout?id_token_hint=${
        tokens.idToken.token
      }&post_logout_redirect_uri=${
        isUsProdEnv ? process.env.REACT_APP_REDIRECT_URL : process.env.REACT_APP_BASE_URL
      }`
    )
  } else if (withRedirection && !isRootLocation()) {
    redirectToRoot()
  } else {
    const redirectUrl = isUsProdEnv
      ? `${process.env.REACT_APP_BASE_URL}/sso/login`
      : (process.env.REACT_APP_BASE_URL as string)
    redirectTo(redirectUrl)
  }
}

export const isUserLocked = (lockAt?: number | null): boolean =>
  lockAt ? moment.unix(lockAt).isSameOrBefore(moment()) : false

export const isUserExpired = (role: UserType, expiration?: number | null): boolean =>
  expiration ? role === "external_user" && moment.unix(expiration).isSameOrBefore(moment()) : false
