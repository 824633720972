import { platformSharedLocales } from "@moodys/cre-cpm-platform-shared.locales"
import deJson from "../locales/auth/de.json"
import enJson from "../locales/auth/en.json"
import frJson from "../locales/auth/fr.json"

const locales = {
  en: { auth: enJson, agGrid: { ...platformSharedLocales.en.grid } },
  de: { auth: deJson, agGrid: { ...platformSharedLocales.de.grid } },
  fr: { auth: frJson }
}

export default locales
