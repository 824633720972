import { AuthContext } from "contexts/AuthContext"
import isPublicAccessView from "functions/WindowLocation"
import React, { useContext, useEffect } from "react"

export type AppBootstrapMode = "initializeAndRegister" | "register"
export interface AppBootstrapProps {
  children: React.ReactNode
}

const AppBootstrap: React.FC<AppBootstrapProps> = ({ children }) => {
  const { validateCredentials } = useContext(AuthContext)

  useEffect(() => {
    if (!isPublicAccessView()) {
      validateCredentials()
    }
  }, [])

  return <div>{children}</div>
}

export default AppBootstrap
