import Navigate from "components/Routing/Navigate"
import { AuthContext } from "contexts/AuthContext"
import { createRedirectToAdminWithCallback } from "functions/User/Session"
import { UserType } from "@moodys/cre-cpm-client-apis.apis.services.auth"
import React, { useContext } from "react"
import { useLocation, useParams } from "react-router-dom"

export interface RedirectUnauthorizedProps {
  minAccessRole?: UserType[]
  matchUserId?: boolean
}

const RedirectUnauthorized: React.FC<RedirectUnauthorizedProps> = ({
  children,
  minAccessRole = [],
  matchUserId
}) => {
  const { id } = useParams<{ id: string }>()
  const { isAuthenticated, userType, isValidatingToken, isSuperAdmin, userId } =
    useContext(AuthContext)

  const isMatchingUserId = () => id === userId?.toString()
  const isAllowed = () =>
    (minAccessRole.includes(userType) || isSuperAdmin()) && (!matchUserId || isMatchingUserId())
  const location = useLocation()
  const isAuthenticatedButNotAllowed = isAuthenticated && !isAllowed()
  const isNotAuthenticated = !isValidatingToken && (!isAuthenticated || !isAllowed())

  return (
    <>
      {isAuthenticated && isAllowed() && children}
      {(isNotAuthenticated || isAuthenticatedButNotAllowed) && (
        <Navigate to={createRedirectToAdminWithCallback(location.pathname)} />
      )}
    </>
  )
}

export default RedirectUnauthorized
