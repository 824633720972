import { DynamicLayout, DynamicLayoutChart } from "@moodys/cre-cpm-client-apis.apis.services.auth"

const dynamicLayoutDefaults = {
  asset_dashboard: {
    commercial: [
      "default_kpis",
      "assets_data",
      "assets_map",
      "units_type_of_use",
      "units_top_tenants",
      "lease_end_date_years",
      "lease_end_date_tenants"
    ] as DynamicLayoutChart[],
    multifamily: [
      "default_kpis",
      "assets_data",
      "assets_map",
      "units_type_of_use",
      "units_apartment_sizes",
      "lease_start_date_years",
      "units_rental_prices"
    ] as DynamicLayoutChart[]
  },
  portfolio_dashboard: {
    commercial: [
      "default_kpis",
      "assets_country_code",
      "units_type_of_use",
      "units_top_tenants",
      "lease_end_date_years",
      "lease_end_date_tenants"
    ] as DynamicLayoutChart[],
    multifamily: [
      "default_kpis",
      "assets_country_code",
      "units_type_of_use",
      "years_of_construction",
      "lease_start_date_years",
      "units_apartment_sizes"
    ] as DynamicLayoutChart[]
  },
  deals_dashboard: {
    volume: [
      "deal_stage_id_volume",
      "deals_status_date_volume",
      "deals_canceled_reason_volume",
      "deals_captain_id_volume",
      "deals_broker_name_volume",
      "deals_risk_profile_volume"
    ] as DynamicLayoutChart[],
    total: [
      "deal_stage_id_total",
      "deals_status_date_total",
      "deals_canceled_reason_total",
      "deals_captain_id_total",
      "deals_broker_name_total",
      "deals_risk_profile_total"
    ] as DynamicLayoutChart[]
  },
  tenants: {
    tenants_dashboard: ["risks_kpis", "latest_year_vs_trigger", "implied_rating_change"],
    tenants_list: [
      "kpis_lender",
      "industry_primary_label",
      "rent_implied_rating",
      "lease_end_date_years"
    ]
  }
}

export const availableLayoutCharts = {
  asset_dashboard: {
    commercial: dynamicLayoutDefaults.asset_dashboard.commercial.concat([
      "units_apartment_sizes",
      "lease_start_date_years",
      "units_rental_prices",
      "tenant_implied_rating_label"
    ]),
    multifamily: dynamicLayoutDefaults.asset_dashboard.multifamily.concat([
      "units_top_tenants",
      "lease_end_date_years",
      "lease_end_date_tenants",
      "unit_type_rent_bars",
      "unit_type_rent_plots",
      "unit_type_lease_ends"
    ])
  },
  portfolio_dashboard: {
    commercial: dynamicLayoutDefaults.portfolio_dashboard.commercial.concat([
      "years_of_construction",
      "lease_start_date_years",
      "units_apartment_sizes"
    ]),
    multifamily: dynamicLayoutDefaults.portfolio_dashboard.multifamily.concat([
      "units_top_tenants",
      "lease_end_date_years",
      "lease_end_date_tenants",
      "units_rental_prices",
      "unit_type_rent_bars",
      "unit_type_rent_plots",
      "unit_type_lease_ends"
    ])
  },
  deals_dashboard: {
    volume: dynamicLayoutDefaults.deals_dashboard.volume,
    total: dynamicLayoutDefaults.deals_dashboard.total
  },
  tenants: {
    tenants_dashboard: dynamicLayoutDefaults.tenants.tenants_dashboard,
    tenants_list: dynamicLayoutDefaults.tenants.tenants_list.concat(["kpis_asset_manager"])
  }
} as DynamicLayout

export default dynamicLayoutDefaults
