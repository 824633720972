import React, { FC } from "react"
import { assetFieldsAffectingCharts, unitFieldsAffectingCharts } from "constants/rentRollFields"
import { Typography } from "antd"
import { FieldProps } from "pages/Admin/Companies/Edit/RentRollTab/RentRollFieldsTable/functions"

const asterisk = <Typography.Text type="danger">*</Typography.Text>

export interface FieldNameCellRendererProps extends FieldProps {
  data: FieldProps["data"]
  type: "unit" | "asset"
}
const FieldNameCellRenderer: FC<FieldNameCellRendererProps> = ({ data, type }) => {
  const isFieldAffectingCharts =
    type === "asset"
      ? assetFieldsAffectingCharts.includes(data.fieldName)
      : unitFieldsAffectingCharts.includes(data.fieldName)

  return (
    <span>
      {data.fieldName}
      &nbsp;
      {isFieldAffectingCharts && asterisk}
    </span>
  )
}

export default FieldNameCellRenderer
