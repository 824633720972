import loanSensitivityRanges from "constants/loan-sensitivity"
import { TFunction } from "i18next"
import { SensitivityBarMode } from "../.."

export const getTitle = (mode: SensitivityBarMode, t: TFunction): string => {
  switch (mode) {
    case "dscr":
      return t("companies.sensitivity.dscr")
    case "debt_yield":
      return t("companies.sensitivity.debtyield")
    case "ltv":
      return t("companies.sensitivity.ltv")
    default:
      throw new Error("Sensitivity bar: invalid mode value")
  }
}

export const getStep = (mode: SensitivityBarMode): number => {
  switch (mode) {
    case "dscr":
      return 0.25
    default:
      return 1
  }
}

const parseInput = (mode: SensitivityBarMode, value: string): number => {
  const currentStep = getStep(mode)
  const numberValue = parseFloat(value)
  const minValue = loanSensitivityRanges[mode].lower
  const maxValue = loanSensitivityRanges[mode].upper

  if (numberValue < minValue) return minValue
  if (numberValue > maxValue) return maxValue

  const remainder = numberValue % currentStep
  if (remainder === 0) return numberValue

  const candidate = numberValue - remainder
  if (candidate >= minValue) return candidate

  return candidate + currentStep
}
export const parseInputMin =
  (mode: SensitivityBarMode) =>
  (value: string | undefined): number => {
    return !value ? loanSensitivityRanges[mode].lower : parseInput(mode, value)
  }
export const parseInputMax =
  (mode: SensitivityBarMode) =>
  (value: string | undefined): number => {
    return !value ? loanSensitivityRanges[mode].upper : parseInput(mode, value)
  }
