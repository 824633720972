import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Button, Col, Form, FormInstance, Input, Row, Space, Typography } from "antd"
import { Stylesheet, ColorKey, Company } from "@moodys/cre-cpm-client-apis.apis.services.auth"
import { getTestId } from "@moodys/cre-cpm.functions.testing"
import ColorPickerBox from "components/Admin/ColorPickerBox"
import { CompanyForm } from "models/company-form.model"
import getConfiguration, { getInitialState } from "./functions"

export interface BrandTabProps {
  company?: Company
  form: FormInstance<CompanyForm>
}

const BrandTab: React.FC<BrandTabProps> = ({ form, company }) => {
  const { t } = useTranslation("auth")
  const configuration = getConfiguration(t)
  const [stylesheet, setStylesheet] = useState<Stylesheet>(
    getInitialState(configuration, company?.attributes.style)
  )
  const onChangeColor = (colorKey: ColorKey, color: string) => {
    const newColors = {
      ...stylesheet,
      [colorKey]: color
    }

    setStylesheet(newColors)
  }

  const hasDefaultColors = () =>
    !Object.keys(configuration).some((key) => configuration[key].defaultColor !== stylesheet[key])

  const createColorsFromState = () => ({
    ...stylesheet,
    primary_color_map:
      configuration.primary_color.defaultColor !== stylesheet.primary_color
        ? stylesheet.primary_color
        : stylesheet.primary_color_map,
    secondary_color_map:
      configuration.secondary_color.defaultColor !== stylesheet.secondary_color
        ? stylesheet.secondary_color
        : stylesheet.secondary_color_map,
    primary_text_color_map:
      configuration.primary_text_color.defaultColor !== stylesheet.secondary_color
        ? stylesheet.primary_text_color
        : stylesheet.primary_color_map
  })

  const resetToDefault = () => {
    setStylesheet(getInitialState(configuration))
  }

  useEffect(() => {
    form.setFieldsValue({
      style: createColorsFromState()
    })
    // eslint-disable-next-line
  }, [stylesheet])

  useEffect(() => {
    if (company?.attributes.style) {
      setStylesheet(getInitialState(configuration, company?.attributes.style))
    }
    // eslint-disable-next-line
  }, [company?.attributes.style])

  return (
    <Row>
      <Form.Item name="style" initialValue={company?.attributes.style}>
        <Input type="hidden" data-testid={getTestId("brand__style-hidden")} />
      </Form.Item>
      <Space size="large" direction="vertical" className="rxd-full-width">
        <Col span={24}>
          <Typography.Title level={4}>{t("companies.brand.title.text")}</Typography.Title>
        </Col>
        <Col span={24}>
          <Row>
            {(Object.keys(configuration) as ColorKey[]).map(
              (key: ColorKey, ndx: number) =>
                configuration[key].show && (
                  <Col span={7} key={key} offset={ndx % 3 === 0 ? "" : "1"}>
                    <ColorPickerBox
                      hexValue={stylesheet[key]}
                      name={configuration[key].title}
                      description={configuration[key].description}
                      id={key}
                      onChange={onChangeColor}
                      testId={getTestId("brand__color_picker_box", key)}
                    />
                  </Col>
                )
            )}
          </Row>
        </Col>
        <Col span={24}>
          <Button
            type="primary"
            onClick={resetToDefault}
            disabled={hasDefaultColors()}
            data-testid={getTestId("brand_tab__reset_colors_btn")}
          >
            {t("shared.reset_default")}
          </Button>
        </Col>
      </Space>
    </Row>
  )
}

export default BrandTab
