import React from "react"
import { useTranslation } from "react-i18next"
import { Button, Result } from "antd"
import ScreenCenterContent from "components/Shared/ScreenCenterContent"

interface SsoLoginRedirectProps {
  message?: string
  link?: string
}

const SsoLoginRedirect: React.FC<SsoLoginRedirectProps> = ({ message, link }) => {
  const { t } = useTranslation("auth")
  return (
    <ScreenCenterContent colWidth={12}>
      <Result
        title={t(message || "sso.logOn.message")}
        extra={
          <Button
            type="primary"
            key="console"
            href={link || `${process.env.REACT_APP_BASE_URL}/sso/login`}
          >
            {t("sso.logOn.button")}
          </Button>
        }
      />
    </ScreenCenterContent>
  )
}

export default SsoLoginRedirect
