import React from "react"
import { useTranslation } from "react-i18next"
import { Form, Input } from "antd"
import { CompanyFilter } from "@moodys/cre-cpm-client-apis.apis.services.auth"
import { PaginationFilters } from "@moodys/cre-cpm-client-apis.apis.api-base"
import { getTestId } from "@moodys/cre-cpm.functions.testing"
import { defaultPagination } from "contexts/CompanyContext"

const { Search } = Input

export interface CompanyFilterProps {
  setFilters: (filter: CompanyFilter) => void
  setPagination: (pagination: PaginationFilters) => void
  filters: CompanyFilter
  loading: boolean
}

const Filter: React.FC<CompanyFilterProps> = ({ setFilters, setPagination, filters, loading }) => {
  const [form] = Form.useForm()
  const { t } = useTranslation("auth")

  const onSearch = (search = "") => {
    if (search !== filters.like) {
      setPagination(defaultPagination)
      setFilters({ ...filters, like: search?.trim() })
    }
  }
  return (
    <Form
      layout="horizontal"
      form={form}
      initialValues={{ by: filters.like }}
      data-testid={getTestId("company_search_form")}
    >
      <Form.Item name="search" rules={[{ min: 3 }]}>
        <Search
          placeholder={t("shared.search_by_company_name")}
          onSearch={onSearch}
          allowClear
          loading={loading}
          disabled={loading}
          minLength={3}
          data-testid={getTestId("company_search_input")}
        />
      </Form.Item>
    </Form>
  )
}

export default Filter
