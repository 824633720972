import { Spin } from "antd"
import { AuthContext } from "contexts/AuthContext"
import { UserType } from "@moodys/cre-cpm-client-apis.apis.services.auth"
import React, { useContext } from "react"
import { useParams } from "react-router-dom"

export interface RequireAuthProps {
  minAccessRole?: UserType[]
  useSpinOnValidating?: boolean
  matchUserId?: boolean
}

const RequireAuth: React.FC<RequireAuthProps> = ({
  children,
  minAccessRole = [],
  useSpinOnValidating,
  matchUserId
}) => {
  const { id } = useParams<{ id: string }>()
  const { isAuthenticated, userType, isValidatingToken, isSuperAdmin, userId } =
    useContext(AuthContext)
  const isMatchingUserId = () => id === userId?.toString()
  const isAllowed = () =>
    (minAccessRole.includes(userType) || isSuperAdmin()) && (!matchUserId || isMatchingUserId())

  return useSpinOnValidating && isValidatingToken ? (
    <Spin spinning={useSpinOnValidating && isValidatingToken} />
  ) : (
    // We bypass the rule because: https://github.com/vercel/next.js/issues/36019#issuecomment-1096413284
    // Doing like return children cause false type error
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{isAuthenticated && isAllowed() && children}</>
  )
}

export default RequireAuth
