import { getTestId } from "@moodys/cre-cpm.functions.testing"
import { Testable } from "@moodys/cre-cpm.models.testable"
import React from "react"
import "./index.less"

export interface ColorPickerButtonProps extends Testable {
  hexValue: string
  onClick: () => void
}

const ColorPickerButton: React.FC<ColorPickerButtonProps> = ({ hexValue, testId, onClick }) => (
  <button
    onClick={onClick}
    style={{ backgroundColor: hexValue }}
    className="rxd-color-picker-button"
    type="button"
    aria-label="button"
    data-testid={getTestId("color_picker_btn_cp__btn", testId)}
  />
)

export default ColorPickerButton
