import { faBars } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import mapToDraggableTableRow from "components/Admin/DragabbleTable/functions"
import dynamicLayoutDefaults, { availableLayoutCharts } from "constants/dynamicLayout"
import { AntdAlignType } from "constants/shared"
import { addArrayWithoutDuplicates } from "@moodys/cre-cpm.functions.array"
import { TFunction } from "i18next"
import {
  DynamicLayout,
  DynamicLayoutChart,
  DynamicLayoutDashboardKey,
  DynamicLayoutDashboardSectionKey
} from "@moodys/cre-cpm-client-apis.apis.services.auth"
import React from "react"

export const configuration = [
  {
    title: "Chart",
    dataIndex: "name",
    key: "name"
  },
  {
    title: "Icon",
    dataIndex: "icon",
    key: "icon",
    render: () => <FontAwesomeIcon icon={faBars} />,
    align: "right" as AntdAlignType
  }
]

export const getKey = (
  dashboard: DynamicLayoutDashboardKey,
  section: DynamicLayoutDashboardSectionKey
): string => `${dashboard}_${section}`

export const getInitialSelectedRows = (layouts?: DynamicLayout) => {
  const map = new Map()
  const dashboards = Object.keys(layouts ?? dynamicLayoutDefaults) as DynamicLayoutDashboardKey[]
  dashboards.forEach((dashboard) =>
    (
      Object.keys(
        layouts ? layouts[dashboard] : dynamicLayoutDefaults[dashboard]
      ) as DynamicLayoutDashboardSectionKey[]
    ).forEach((section) => {
      map.set(
        getKey(dashboard, section),
        layouts ? layouts[dashboard][section] : dynamicLayoutDefaults[dashboard][section]
      )
    })
  )

  return map
}

export const getInitialAvailableCharts = (
  dashboard: DynamicLayoutDashboardKey,
  section: DynamicLayoutDashboardSectionKey,
  layout?: DynamicLayout
): DynamicLayoutChart[] => {
  if (!layout || !layout[dashboard] || !layout[dashboard][section])
    return availableLayoutCharts[dashboard][section] ?? []
  return (
    addArrayWithoutDuplicates(
      layout[dashboard][section] ?? [],
      availableLayoutCharts[dashboard][section] ?? []
    ) ?? []
  )
}

export const isCheckboxDisabled = (
  key: DynamicLayoutChart,
  selectedRows?: React.Key[]
): boolean => {
  switch (key) {
    case "kpis_asset_manager":
      return !selectedRows?.includes("kpis_lender")

    case "kpis_lender":
      return !selectedRows?.includes("kpis_asset_manager")

    default:
      return true
  }
}

export const isCheckboxDisabledByDefault = (chart: DynamicLayoutChart) =>
  chart === "kpis_asset_manager"

export const getInitialDataSource = (t: TFunction, layout?: DynamicLayout) => {
  const map = new Map()
  const dashboards = Object.keys(availableLayoutCharts) as DynamicLayoutDashboardKey[]
  dashboards.forEach((dashboard) => {
    ;(Object.keys(availableLayoutCharts[dashboard]) as DynamicLayoutDashboardSectionKey[]).forEach(
      (section) => {
        map.set(
          getKey(dashboard, section),
          mapToDraggableTableRow(
            getInitialAvailableCharts(dashboard, section, layout),
            (chart: DynamicLayoutChart) => ({
              key: chart,
              name: t(`companies.layout.charts.${chart}`),
              enabled: !isCheckboxDisabledByDefault(chart)
            })
          )
        )
      }
    )
  })

  return map
}

export const getDefaultSelectedKeys = (
  dashboard: DynamicLayoutDashboardKey,
  section: DynamicLayoutDashboardSectionKey
) =>
  (getInitialSelectedRows(dynamicLayoutDefaults as DynamicLayout) as Map<string, React.Key[]>).get(
    getKey(dashboard, section)
  )
