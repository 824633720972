import { AgGridReact, AgGridReactProps } from "ag-grid-react"
import { GetLocaleTextParams } from "ag-grid-community"
import React, { forwardRef, LegacyRef } from "react"
import authI18n from "functions/I18n"
import "ag-grid-enterprise"

export const getLocaleText = (params: GetLocaleTextParams): string =>
  authI18n.t(`${params.key}`, { ns: "agGrid" })

const AgGrid = forwardRef(
  ({ components, ...props }: AgGridReactProps, ref: LegacyRef<AgGridReact>) => {
    return (
      <AgGridReact
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        ref={ref}
        components={{
          ...components
        }}
        getLocaleText={getLocaleText}
        suppressScrollOnNewData
      />
    )
  }
)

export default AgGrid
