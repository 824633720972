import { MenuKeys, SubMenuKeys } from "pages/Admin/Menu/functions"
import React, { useMemo, useState } from "react"

export interface LayoutContextProps {
  openKeys: SubMenuKeys[]
  menuKey?: MenuKeys
  isMenuCollapsed: boolean
  setMenuCollapsed: (collapsed: boolean) => void
  setOpenKeys: (key: SubMenuKeys[]) => void
  mergeOpenKeys: (key: SubMenuKeys) => void
  setMenuKey: (key: MenuKeys) => void
  hasChanges: (subMenuKey: SubMenuKeys, mKey?: MenuKeys) => boolean
}

export interface LayoutContextProviderProps {
  children: React.ReactNode
}

const initialLayoutContext: LayoutContextProps = {
  openKeys: [],
  menuKey: undefined,
  isMenuCollapsed: true,
  setMenuCollapsed: () => {},
  setOpenKeys: () => {},
  setMenuKey: () => {},
  mergeOpenKeys: () => {},
  hasChanges: () => false
}

export const LayoutContext = React.createContext(initialLayoutContext)

export const LayoutContextProvider = ({ children }: LayoutContextProviderProps) => {
  const [menuKey, setMenuKey] = useState(initialLayoutContext.menuKey)
  const [openKeys, setOpenKeys] = useState(initialLayoutContext.openKeys)
  const [isMenuCollapsed, setMenuCollapsed] = useState(true)

  const mergeOpenKeys = (key: SubMenuKeys) => {
    const newOpenKeys = openKeys.filter((k) => key !== k).concat([key])
    setOpenKeys(newOpenKeys)
  }

  const hasChanges = (subMenuKey: SubMenuKeys, mKey?: MenuKeys) =>
    !(openKeys.includes(subMenuKey) && menuKey === mKey)

  const values = useMemo(
    () => ({
      menuKey,
      openKeys,
      isMenuCollapsed,
      setMenuCollapsed,
      setMenuKey,
      setOpenKeys,
      mergeOpenKeys,
      hasChanges
    }),
    [menuKey, openKeys, isMenuCollapsed]
  )

  return <LayoutContext.Provider value={values}>{children}</LayoutContext.Provider>
}
