import useStore from "components/Admin/hooks/useStore"
import React, { useMemo } from "react"

export interface StoreContextProps {
  get: <T>(key: string) => T | undefined
  set: <T>(key: string, value: T) => void
  remove: (key: string) => void
}

export interface StoreContextProviderProps {
  children: React.ReactNode
}

const initialStoreContext: StoreContextProps = {
  get: () => undefined,
  set: () => {},
  remove: () => {}
}

export const DefaultEmptyStoreContext = React.createContext(initialStoreContext)

export const PortfolioStoreContext = React.createContext(initialStoreContext)

export const PortfolioStoreContextProvider = ({ children }: StoreContextProviderProps) => {
  const [get, set, remove] = useStore()

  const values = useMemo(
    () => ({
      get,
      set,
      remove
    }),
    []
  )

  return <PortfolioStoreContext.Provider value={values}>{children}</PortfolioStoreContext.Provider>
}
