import { faPencilAlt } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Tooltip } from "antd"
import { getTestId } from "@moodys/cre-cpm.functions.testing"
import { Testable } from "@moodys/cre-cpm.models.testable"
import React from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

export interface ExportButtonProps extends Testable {
  to: string
}

const EditLink: React.FC<ExportButtonProps> = ({ to, testId }) => {
  const { t } = useTranslation("auth")

  return (
    <Tooltip placement="top" title={t(`shared.edit`)}>
      <Link to={to} type="link" data-testid={getTestId("edit_link", testId)}>
        <FontAwesomeIcon icon={faPencilAlt} />
      </Link>
    </Tooltip>
  )
}

export default React.memo(EditLink)
