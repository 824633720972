import { Col, Row, Space } from "antd"
import Logo from "components/Admin/Logo"
import { getTestId } from "@moodys/cre-cpm.functions.testing"
import { Testable } from "@moodys/cre-cpm.models.testable"
import React from "react"
import "./index.less"

export interface SidebarHeaderProps extends Testable {
  collapsed: boolean
}

const Header: React.FC<SidebarHeaderProps> = ({ collapsed, testId }) => (
  <li key="header" data-testid={getTestId("rxd_auth_header", testId)}>
    <Col flex="1 1 auto">
      <Row className="rxd-auth-sidebar-header" justify={collapsed ? "center" : "start"}>
        <Col>
          <Space size="small">
            <Logo />
          </Space>
        </Col>
      </Row>
    </Col>
  </li>
)

export default Header
